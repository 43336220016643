import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Typography, Avatar, Tooltip } from '@mui/material';
import { UserService } from '../../../services/api';
import { User } from "../../../models/Accounts/User";

interface TaskUserSelectProps {
    user: string;
    onTaskUserChange: (newUser: string) => void;
    permission: boolean;
    show: boolean;
    filter: string;
}

const TaskUserSelect: React.FC<TaskUserSelectProps> = ({ user, onTaskUserChange, permission, show, filter }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = useState<string>();
    const [userList, setUserList] = useState<User[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [selectedText, setSelectedText] = useState<string>("");
    const [selectedColor, setSelectedColor] = useState<string>("#0b2343");
    const [selectedUser, setSelectedUser] = useState<string>("");

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                if (!disabled) {
                    const myFilter = {
                        filters: {
                            subrole: filter,
                        },
                        page: 1,
                        limit: 1000,
                        sort: "firstname",
                        order: "asc",
                    };
                    const users = await UserService.getAllWithParam(myFilter, "filter");
                    setUserList(users.users || []);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchUsers();
    }, [disabled, filter]);

    useEffect(() => {
        setSelectedOption(user || '');
        setDisabled(permission);
        const currentUser = userList.find(
            (user) => user._id === selectedOption
        );
        if (currentUser) {
            setSelectedText(currentUser?.initialsText);
            setSelectedColor(currentUser?.initialsColor);
            setSelectedUser(currentUser?.firstname + " " + currentUser?.lastname);
        }

    }, [user, permission, userList, selectedOption]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        onTaskUserChange(option);
        handleClose();
    };

    return (
        <div
            style={{ padding: '5px' }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
        >
            <Typography
                variant="body1"
                onClick={handleClick}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                <Tooltip title={selectedUser || ''}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                width: 40,
                                height: 40,
                                backgroundColor: selectedColor,
                            }}
                        >
                            {selectedText}
                        </Avatar>
                        {show ? (
                            <span style={{ marginLeft: '8px' }}>{selectedUser}</span>
                        ) : null}
                    </div>
                </Tooltip>
            </Typography>

            {!disabled ? (
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {userList.map((user, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleOptionClick(user._id)}
                            disabled={disabled}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: user.initialsColor,
                                    }}
                                >
                                    {user.initialsText}
                                </Avatar>
                                <span style={{ marginLeft: '8px' }}>
                                    {user.firstname + " " + user.lastname}
                                </span>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            ) : null}
        </div>
    );
};

export default TaskUserSelect;
