import React, { useState, useEffect, useCallback } from 'react';
import { TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RegulationRefDetail from './RegulationRefDetail';
import regulation from '../data/regulation';
import { useTranslation } from 'react-i18next';

interface TaskCombinedFieldProps {
  item: string;
  multiline: boolean;
  rows: number;
  label: boolean;
  disabled: boolean;
  onTaskCombinedFieldChange: (newCombinedField: string) => void;
  onBlurCallBack: (isBlurred: boolean) => void;
};

const TaskCombinedField: React.FC<TaskCombinedFieldProps> = ({ item, multiline, rows, label, disabled, onTaskCombinedFieldChange, onBlurCallBack }) => {
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [regulationRefOpen, setReguletionRefOpen] = useState<boolean>(false);
  const [regulationDetailData, setRegulationDetailData] = useState<string>("");
  const {t} = useTranslation('global');

  const getRegulationData = useCallback((regu: string) => {
    const myRegulation = regu.split("/");
    const title = myRegulation[0];
    const article = myRegulation[1];
    const parag = myRegulation[2];
  
    const mydetail = regulation.find((item) => item["Regulation Ref."] === title + " " + article + " " + parag);
    setRegulationDetailData(mydetail?.Content || "");
  }, []);

  useEffect(() => {
    getRegulationData(item);
  }, [item, getRegulationData]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onTaskCombinedFieldChange(value);
    setAnyChange(true);
  }, [onTaskCombinedFieldChange]);

  const handleBlur = useCallback(() => {
    if (anyChange) {
      onBlurCallBack(true);
      setAnyChange(false);
    }
  }, [anyChange, onBlurCallBack]);

  return (

    <div>
      <RegulationRefDetail
        open={regulationRefOpen}
        onClose={() => { setReguletionRefOpen(!regulationRefOpen) }}
        content={regulationDetailData}
      />
      <TextField
        fullWidth
        label={!label ? null : t('task.regulation')}
        value={item}
        onChange={handleChange}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        onBlur={handleBlur}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {regulationDetailData !== "" ? (
                  <Tooltip title="Click for details">
                    <IconButton onClick={() => { setReguletionRefOpen(!regulationRefOpen) }} style={{ width: rows === 1 ? "14px" : "24px", height: rows === 1 ? "14px" : "24px", cursor: "pointer" }}>
                      <QuestionMarkIcon style={{ width: rows === 1 ? "14px" : "24px", height: rows === 1 ? "14px" : "24px", color: "#6abc45", fontWeight: "bold", cursor: "pointer" }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </InputAdornment>
            ),
          },
        }}
      />
    </div>
  );
};

export default TaskCombinedField;