import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import Flag from 'react-world-flags';

const LanguageSelector: React.FC = () => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = async (lang: string) => {
        await i18n.changeLanguage(lang);
        handleClose();
    };

    const getCurrentFlagCode = () => {
        switch (i18n.language) {
            case 'tr':
                return 'TR';
            case 'en':
                return 'USA';
            default:
                return 'USA';
        }
    };

    return (
        <div>
            <IconButton sx={{ color: '#0b2343' }} onClick={handleClick}>
                <Flag code={getCurrentFlagCode()} style={{ width: 20, height: 20, marginRight: 10 }} />
                <LanguageIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => changeLanguage("tr")}>
                    <Flag code="TR" style={{ width: 20, height: 20, marginRight: 10 }} />
                    Türkçe
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("en")}>
                    <Flag code="USA" style={{ width: 20, height: 20, marginRight: 10 }} />
                    English
                </MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSelector;
