import React, { useState } from 'react';
import { Box, IconButton, Checkbox, FormControlLabel, Select, MenuItem, FormControl, TextField, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';

import TaskUserSelect from './TaskUserSelect';
import { useTranslation } from 'react-i18next';


interface TaskFilterProps {
    onConditionsChange: (conditions: Array<Record<string, any>>) => void;
}

const TaskFilter: React.FC<TaskFilterProps> = ({ onConditionsChange }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [statusChecked, setStatusChecked] = useState<boolean>(false);
    const [responsibleChecked, setResponsibleChecked] = useState<boolean>(false);
    const [targetDateChecked, setTargetDateChecked] = useState<boolean>(false);
    const [requestDateChecked, setRequestDateChecked] = useState<boolean>(false);
    const [creationDateChecked, setCreationDateChecked] = useState<boolean>(false);

    const [selectedStatus, setSelectedStatus] = useState<string>("Not Assigned");
    const [selectedResponsible, setSelectedResponsible] = useState<string>("");
    const [selectedTargetDate, setSelectedTargetDate] = useState<Date | null>(new Date());
    const [selectedRequestDate, setSelectedRequestDate] = useState<Date | null>(new Date());
    const [selectedCreationDate, setCreationDate] = useState<Date | null>(new Date());

    const [statusCondition, setStatusCondition] = useState<string>("");
    const [responsibleCondition, setResponsibleCondition] = useState<string>("");
    const [targetDateCondition, setTargetDateCondition] = useState<string>("");
    const [requestDateCondition, setRequestDateCondition] = useState<string>("");
    const [creationDateCondition, setCreationDateCondition] = useState<string>("");


    const statusList = ["Cancelled", "Not Assigned", "To Do", "In Progress", "Awaiting Approver", "Awaiting Int. Auditor", "Submitted", "Done"];
    const conditionList = ["is", "is not", "before", "after", "equals", "not equals"];
    const {t} = useTranslation('global');

    const language: Record<string,string> = {
        "Cancelled": t('task.cancelled'),
        "Not Assigned": t('task.not_assigned'),
        "To Do":  t('task.to_do'),
        "In Progress":  t('task.in_progress'),
        "Awaiting Approver":  t('task.awaiting_approver'),
        "Awaiting Int. Auditor":  t('task.awaiting_int_auditor'),
        "Submitted":  t('task.submitted'),
        "Done":  t('task.done'),
        "IS": t('task.is'),
        "IS NOT": t('task.is_not'),
        "BEFORE": t('task.before'),
        "AFTER": t('task.after'),
        "EQUALS": t('task.equals'),
        "NOT EQUALS": t('task.not_equals'),
    };

    const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        if (value === "Done") {
            return { backgroundColor: "#377d51", color: "#FFFFFF" };
        } else if (value === "Not Assigned") {
            return { backgroundColor: "#F0F0F0", color: "#000000" };
        } else if (value === "Cancelled") {
            return { backgroundColor: "#c10101", color: "#FFFFFF" };
        } else if (value === "In Progress") {
            return { backgroundColor: "#FFFACD", color: "#000000" };
        } else if (value === "Awaiting Int. Auditor") {
            return { backgroundColor: "#f3b056", color: "#000000" };
        } else if (value === "Submitted") {
            return { backgroundColor: "#5ac57b", color: "#FFFFFF" };
        } else if (value === "Awaiting Approver") {
            return { backgroundColor: "#F0E68C", color: "#000000" };
        } else if (value === "To Do") {
            return { backgroundColor: "#EEE8AA", color: "#000000" };
        }
        return undefined;
    };
    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const handleCheckBoxChange = (field: string) => {
        switch (field) {
            case "status":
                setStatusChecked(!statusChecked);
                break;
            case "responsible":
                setResponsibleChecked(!responsibleChecked);
                break;
            case "targetDate":
                setTargetDateChecked(!targetDateChecked);
                break;
            case "requestDate":
                setRequestDateChecked(!requestDateChecked);
                break;
            case "creationDate":
                setCreationDateChecked(!creationDateChecked);
                break;
            default:
                break;
        }
    };

    const handleTargetDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedTargetDate(event.target.value ? new Date(event.target.value) : null);
    };

    const handleRequestDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRequestDate(event.target.value ? new Date(event.target.value) : null);
    };

    const handleCreationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreationDate(event.target.value ? new Date(event.target.value) : null);
    };

    const clearFilter = () => {
        setSelectedStatus("");
        setSelectedResponsible("");
        setSelectedTargetDate(null);
        setSelectedRequestDate(null);
        setCreationDate(null);

        setStatusChecked(false);
        setResponsibleChecked(false);
        setTargetDateChecked(false);
        setRequestDateChecked(false);
        setCreationDateChecked(false);

        setStatusCondition("");
        setResponsibleCondition("");
        setTargetDateCondition("");
        setRequestDateCondition("");
        setCreationDateCondition("");

    };


    const saveFilter = () => {
        const conditions = [
            { checked: statusChecked, field: "status", value: selectedStatus, conditionType: statusCondition },
            { checked: responsibleChecked, field: "responsible", value: selectedResponsible, conditionType: responsibleCondition },
            { checked: targetDateChecked, field: "targetDate", value: selectedTargetDate?.toISOString().split("T")[0], conditionType: targetDateCondition },
            { checked: requestDateChecked, field: "requestDate", value: selectedRequestDate?.toISOString().split("T")[0], conditionType: requestDateCondition },
            { checked: creationDateChecked, field: "creationDate", value: selectedCreationDate?.toISOString().split("T")[0], conditionType: creationDateCondition }
        ];

        const filters = conditions
            .filter(condition => condition.checked)
            .map(condition => ({
                field: condition.field,
                value: condition.value,
                conditionType: condition.conditionType,
                caseSensitive: false
            }));

        onConditionsChange(filters);
        setIsVisible(false);
    };




    return (
        <div style={{ position: 'relative' }}>
            <IconButton onClick={handleButtonClick}>
                {isVisible ? <FilterListOffIcon /> : <FilterListIcon />}
            </IconButton>

            {isVisible && (
                <Box
                    sx={{
                        minWidth: '40vw',
                        minHeight: '18vw',
                        backgroundColor: 'white',
                        border: '1px solid',
                        borderColor: 'grey.500',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50px',
                        zIndex: 1,
                        padding: '16px',
                        borderRadius: "10px",
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'
                    }}
                >
                    <IconButton
                        onClick={handleButtonClick}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid container columns={12} sx={{ marginTop: 2 }}>
                        <Grid container size={12} display="flex" alignItems="center" sx={{ marginLeft: 5, marginBottom: 1 }} columnSpacing={3}>
                            <Grid size={3} justifyContent="center">
                                <FormControlLabel
                                    checked={statusChecked}
                                    onChange={() => handleCheckBoxChange("status")}
                                    control={<Checkbox />}
                                    label={<span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('task.status')}</span>}
                                />
                            </Grid>

                            <Grid size={3} justifyContent="center">
                                {statusChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={statusCondition}
                                            onChange={(e) => setStatusCondition(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={{ backgroundColor: "#F0F0F0", color: "#000000" }}
                                        >
                                            {conditionList.slice(0, 2).map((condition) => (
                                                <MenuItem key={condition} value={condition} style={{ backgroundColor: "#F0F0F0", color: "#000000" }}>
                                                    {language[condition.toUpperCase()]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>

                            <Grid size={5} justifyContent="center">
                                {statusChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={selectedStatus}
                                            onChange={(e) => setSelectedStatus(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={getColor(selectedStatus)}
                                        >
                                            {statusList.map((status) => (
                                                <MenuItem key={status} value={status} style={getColor(status)}>
                                                    {language[status]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>

                        </Grid>
                        <Grid container size={12} display="flex" alignItems="center" sx={{ marginLeft: 5, marginBottom: 1 }} columnSpacing={3}>
                            <Grid size={3} justifyContent="center">
                                <FormControlLabel
                                    checked={responsibleChecked}
                                    onChange={() => handleCheckBoxChange("responsible")}
                                    control={<Checkbox />}
                                    label={<span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('task.responsible')}</span>}
                                />
                            </Grid>

                            <Grid size={3} justifyContent="center">
                                {responsibleChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={responsibleCondition}
                                            onChange={(e) => setResponsibleCondition(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={{ backgroundColor: "#F0F0F0", color: "#000000" }}
                                        >
                                            {conditionList.slice(0, 2).map((condition) => (
                                                <MenuItem key={condition} value={condition} style={{ backgroundColor: "#F0F0F0", color: "#000000" }}>
                                                    {language[condition.toUpperCase()]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>

                            <Grid size={5} justifyContent="center">
                                {responsibleChecked && (
                                    <TaskUserSelect
                                        user={selectedResponsible}
                                        onTaskUserChange={(newUser) => { setSelectedResponsible(newUser) }}
                                        permission={false}
                                        show={true}
                                        filter='Participant'
                                    />
                                )}
                            </Grid>

                        </Grid>
                        <Grid container size={12} display="flex" alignItems="center" sx={{ marginLeft: 5, marginBottom: 1 }} columnSpacing={3}>
                            <Grid size={3}>
                                <FormControlLabel
                                    checked={targetDateChecked}
                                    onChange={() => handleCheckBoxChange("targetDate")}
                                    control={<Checkbox />}
                                    label={<span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('task.target_date')}</span>}
                                />
                            </Grid>
                            <Grid size={3} justifyContent="center">
                                {targetDateChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={targetDateCondition}
                                            onChange={(e) => setTargetDateCondition(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={{ backgroundColor: "#F0F0F0", color: "#000000" }}
                                        >
                                            {conditionList.slice(2).map((condition) => (
                                                <MenuItem key={condition} value={condition} style={{ backgroundColor: "#F0F0F0", color: "#000000" }}>
                                                    {language[condition.toUpperCase()]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid size={5}>
                                {targetDateChecked && (
                                    <TextField
                                        fullWidth
                                        type='date'
                                        onChange={handleTargetDateChange}
                                        value={selectedTargetDate ? selectedTargetDate.toISOString().split('T')[0] : ''} // Düzeltme
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container size={12} display="flex" alignItems="center" sx={{ marginLeft: 5, marginBottom: 1 }} columnSpacing={3}>
                            <Grid size={3}>
                                <FormControlLabel
                                    checked={requestDateChecked}
                                    onChange={() => handleCheckBoxChange("requestDate")}
                                    control={<Checkbox />}
                                    label={<span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('task.request_date')}</span>}
                                />
                            </Grid>
                            <Grid size={3} justifyContent="center">
                                {requestDateChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={requestDateCondition}
                                            onChange={(e) => setRequestDateCondition(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={{ backgroundColor: "#F0F0F0", color: "#000000" }}
                                        >
                                            {conditionList.slice(2).map((condition) => (
                                                <MenuItem key={condition} value={condition} style={{ backgroundColor: "#F0F0F0", color: "#000000" }}>
                                                    {language[condition.toUpperCase()]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid size={5}>
                                {requestDateChecked && (
                                    <TextField
                                        fullWidth
                                        type='date'
                                        onChange={handleRequestDateChange}
                                        value={selectedRequestDate ? selectedRequestDate.toISOString().split('T')[0] : ''}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container size={12} display="flex" alignItems="center" sx={{ marginLeft: 5, marginBottom: 7 }} columnSpacing={3}>
                            <Grid size={3}>
                                <FormControlLabel
                                    checked={creationDateChecked}
                                    onChange={() => handleCheckBoxChange("creationDate")}
                                    control={<Checkbox />}
                                    label={<span style={{ fontWeight: "bold", color: "#0b2343" }}>{t('task.creation_date')}</span>}
                                />
                            </Grid>
                            <Grid size={3} justifyContent="center">
                                {creationDateChecked && (
                                    <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                                        <Select
                                            value={creationDateCondition}
                                            onChange={(e) => setCreationDateCondition(e.target.value)}
                                            displayEmpty
                                            sx={{ marginTop: '16px', fontSize: '1' }}
                                            style={{ backgroundColor: "#F0F0F0", color: "#000000" }}
                                        >
                                            {conditionList.slice(2).map((condition) => (
                                                <MenuItem key={condition} value={condition} style={{ backgroundColor: "#F0F0F0", color: "#000000" }}>
                                                    {language[condition.toUpperCase()]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid size={5}>
                                {creationDateChecked && (
                                    <TextField
                                        fullWidth
                                        type='date'
                                        onChange={handleCreationDateChange}
                                        value={selectedCreationDate ? selectedCreationDate.toISOString().split('T')[0] : ''}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                            position: 'absolute',
                            bottom: 1,
                            left: 10,
                            margin: 2,
                            backgroundColor: "#c10101",
                            color: "white"
                        }}
                        onClick={() => clearFilter()}
                    >
                        {t('task.clear')}
                    </Button>

                    <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                            position: 'absolute',
                            bottom: 1,
                            right: 10,
                            margin: 2,
                            backgroundColor: "#102240",
                            color: "white"
                        }}
                        onClick={() => saveFilter()}
                    >
                        {t('task.filter')}
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default TaskFilter;
