import React from "react";
import { Box } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


const GeneralError: React.FC = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                backgroundColor: "#FFFFE0",
                borderRadius: "16px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: "row",
                gap: "8px",
                width: "100%",
                maxWidth: "600px",
                margin: "0 auto",
                textAlign: "center", 
                marginBottom: "10px"
            }}
        >
            <ReportProblemIcon style={{color: "red", fontSize: 50}} />
            <p style={{ fontSize: 24, fontWeight: "bold", color: "#0b2343" }}>Something went wrong.</p>
        </Box>


    )
};

export default GeneralError;
