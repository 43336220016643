import React, { useState, useEffect } from "react";
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Collapse } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import BuildIcon from '@mui/icons-material/Build';
// import TaskIcon from '@mui/icons-material/Task';
// import SecurityIcon from '@mui/icons-material/Security'; // For Access Analytics
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BusinessIcon from '@mui/icons-material/Business';
import { Project } from "../../models/Task/Pano";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";

interface SidebarProps {
  allProject: Project[];
}


const Sidebar: React.FC<SidebarProps> = ({ allProject }) => {
  const [openAccount, setOpenAccount] = React.useState(false);
  // const [openDataIntegration, setOpenDataIntegration] = React.useState(false);
  const [openTaskManagement, setOpenTaskManagement] = React.useState(false);
  // const [openAccessAnalytics, setOpenAccessAnalytics] = React.useState(false); // access analytics
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState<Project[]>([]);
  const { user } = useAuth();

  const { t } = useTranslation('global');

  useEffect(() => {
    setProjectList(allProject);
  }, [allProject]);



  const handleAccountClick = () => {
    setOpenAccount(!openAccount);
  };

  // const handleDataIntegrationClick = () => {
  //   setOpenDataIntegration(!openDataIntegration);
  // };

  const handleTaskManagementClick = () => {
    setOpenTaskManagement(!openTaskManagement);
  };

  // use for the access analytics
  // const handleAccessAnalyticsClick = () => {
  //   setOpenAccessAnalytics(!openAccessAnalytics);
  // };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: 'whitesmoke',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      }}
    >


      <Toolbar style={{ backgroundColor: "#0b2343" }} >
        <img src="/assets/images/logo-menu.png" alt="Planitsecure-Logo" onClick={() => { navigate('/dashboard') }} style={{ height: 40, marginBottom: 8, cursor: "pointer" }} />
      </Toolbar>
      <List>
        {/* Dashboard */}
        <ListItemButton component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.dashboard')} />
        </ListItemButton>

        {user?.role === "superadmin" && (
          <ListItemButton component={Link} to="/tenant">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.tenant')} />
          </ListItemButton>
        )}


        {/* Account Management Section */}
        <ListItemButton onClick={handleAccountClick}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.account_management')} />
          {openAccount ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openAccount} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/users">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.users')} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/roles">
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.sub_roles')} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/permissions">
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.permissions')} />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Data Integration Section */}
        {/* <ListItemButton onClick={handleDataIntegrationClick}>
          <ListItemIcon>
            <DataUsageIcon />
          </ListItemIcon>
          <ListItemText primary="Data Integration" />
          {openDataIntegration ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openDataIntegration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/data-integration/config">
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary="Config" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/data-integration/actions">
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Actions" />
            </ListItemButton>
          </List>
        </Collapse> */}

        {/* Task Management Section */}
        <ListItemButton onClick={handleTaskManagementClick} component={Link} to="/task-management/project">
          <ListItemIcon>
            <AutoAwesomeMotionIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.project')} />
          {openTaskManagement ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTaskManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {projectList && projectList.length > 0 ? (
              <>
                {projectList.slice(0, 5).map((project, index) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`/task-management/project-tasks/${project.code}`}
                    key={index}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={project.name} />
                  </ListItemButton>
                ))}

                {projectList.length > 5 && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/task-management/project"
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={`More +${projectList.length - 5} Project`} />
                  </ListItemButton>
                )}
              </>
            ) : null}
          </List>
        </Collapse>


        {/* Access Analytics Section */}
        {/* <ListItemButton onClick={handleAccessAnalyticsClick}>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Access Analytics" />
          {openAccessAnalytics ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openAccessAnalytics} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/access-analytics/rules">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Rules" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/access-analytics/events-notifications">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Events & Notifications" />
            </ListItemButton>
          </List>
        </Collapse> */}

        {/* Settings and Logout */}
        <ListItemButton component={Link} to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.settings')} />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;