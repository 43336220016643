import axios from 'axios';
import { ResponseType } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(
  config => {
    const authantication_token = localStorage.getItem('authantication_token');
    if (authantication_token) {
      config.headers.Authorization = `Bearer ${authantication_token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authantication_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const HandlerService = {
  getAll: async (url: string) => {
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('GET request error:', error);
      throw error;
    }
  },
  post: async (url: string, data: any) => {
    try {
      const response = await api.post(url, data, {
        headers: data instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error('POST request error:', error);
      throw error;
    }
  },
  put: async (url: string, data: any) => {
    try {
      const response = await api.put(url, data, {
        headers: data instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error('PUT request error:', error);
      throw error;
    }
  },
  delete: async (url: string) => {
    try {
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      console.error('DELETE request error:', error);
      throw error;
    }
  },
  get: async (url: string,responseType: ResponseType) => {
    try{
      const response = await api.get(url,{responseType});
      return response.data;
    }catch (error){
      console.error("GET request error:",error);
      throw error;
    }
  },
  putNoData: async (url: string) => {
    try {
      const response = await api.put(url, {headers: { 'Content-Type': 'application/json' }});
      return response.data;
    } catch (error) {
      console.error('PUT request error:', error);
      throw error;
    }
  },
  getAllWithParam: async (url: string, data: any) => {
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      console.error('POST request error:', error);
      throw error;
    }
  },
};

export default HandlerService;
