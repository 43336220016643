import React, { useState } from 'react';
import { Paper, Typography, Tabs, Tab } from "@mui/material";
import Grid from '@mui/material/Grid2';
import EmailConfiguration from '../components/Settings/EmailConfiguration';
import { useTranslation } from 'react-i18next';

const Settings: React.FC = () => {
  const [settingsTabValue, setSettingsTabValue] = useState("email-configuration");
  const { t } = useTranslation('global');

  const handleSettingTabValueChange = (event: React.SyntheticEvent, value: string) => {
    setSettingsTabValue(value);
  };

  return (
    <Paper sx={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)' }}>
      <Grid container sx={{ marginTop: "15px" }}>
        <Grid sx={{ marginTop: 3, marginLeft: 3, width: "100%" }}>
          <Typography variant="h4" sx={{ color: "#0b2343" }}>
            {t('settings.settings')}
          </Typography>
        </Grid>
        <Grid sx={{ margin: 4, width: "100%" }}>
          <Grid sx={{ width: "100%" }}>
            <Tabs
              value={settingsTabValue}
              onChange={handleSettingTabValueChange}
            >
              <Tab
                value="email-configuration"
                label={t('settings.email_configuration')}
                sx={{ color: "#0b2343", fontSize: "18px", textTransform: "none" }}
              />
            </Tabs>
          </Grid>
          <Grid sx={{ margin: 5, width: "100%" }}>
            {settingsTabValue === "email-configuration" && <EmailConfiguration />}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Settings;
