import React, { useState, useEffect } from 'react';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SubRole } from '../../../models/Accounts/SubRole';
import RoleDetail from './RoleDetail';
import { SubRoleService } from '../../../services/api';
import { useTranslation } from 'react-i18next';
import SessionExpire from '../../Common/SessionExpire';

const RoleComponent: React.FC = () => {
    const [allRoles, setAllRoles] = useState<SubRole[]>([]);
    const [roleType, setRoleType] = useState<string>("");
    const [roleDetailOpen, setRoleDetailOpen] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<SubRole | null>(null);
    const { t } = useTranslation('global');
    const [session,setSession] = useState<boolean>(false);

    const getAllRole = async () => {
        try{
            const response = await SubRoleService.getAll();
            const roles: SubRole[] = response.data as SubRole[];
            setAllRoles(roles);
        }catch(error){
            console.log(error);
            setSession(true);
        }

    };

    useEffect(() => {
        getAllRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const deleteRole = async (id: string) => {
        try {
            const response = await SubRoleService.delete(id);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSessionCheck = () =>{
        setSession(false);
        window.location.href = '/login';
    };

    return (
        <Paper>
            <RoleDetail
                open={roleDetailOpen}
                onClose={() => {
                    setRoleDetailOpen(!roleDetailOpen);
                    getAllRole();
                }}
                myRole={selectedRole || null}
                type={roleType}
            />

            <SessionExpire open={session} onClose={handleSessionCheck} />

            <Grid container size={12} sx={{ margin: 5 }}>
                <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                    {t('sub_roles.sub_role_management')}
                </Typography>
            </Grid>

            <Grid container sx={{ margin: 5 }} display="flex" direction="column">

                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => {
                        setRoleType("Create");
                        setRoleDetailOpen(!roleDetailOpen);
                        setSelectedRole(null);
                    }
                    }>
                        {t('sub_roles.create_sub_role')}
                    </Button>
                </Box>

                <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                    <TableHead style={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('sub_roles.name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('sub_roles.permissions')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('sub_roles.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRoles && allRoles.length > 0 ? (
                            allRoles.map((role, index) => (
                                <TableRow key={index}>
                                    <TableCell>{role.name}</TableCell>
                                    <TableCell>
                                        {role.permissions.map((permission, index) => (
                                            <div key={index}>{permission.name}</div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <IconButton onClick={() => {
                                                setSelectedRole(role);
                                                setRoleType("Update");
                                                setRoleDetailOpen(!roleDetailOpen);
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => { deleteRole(role._id) }}>
                                                <DeleteIcon style={{ color: "red" }} />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography align="center">{t('sub_roles.no_role')}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>

        </Paper>
    )
}


export default RoleComponent;
