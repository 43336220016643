import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AddTaskIcon from '@mui/icons-material/AddTask';

interface SuccessfulApprovedProps {
    projectName: string;
    taskID: string;
    taskName: string;
    taskStatus: string;
}

const SuccessfulApproved: React.FC<SuccessfulApprovedProps> = ({projectName,taskID, taskName, taskStatus}) => {

    const [id, setID] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        setID(taskID);
        setName(taskName);
        setStatus(taskStatus);
    }, [taskID,taskName,taskStatus]);


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                backgroundColor: "#f0f8ff",
                borderRadius: "8px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: 'column',
                width: '100%',
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AddTaskIcon sx={{ verticalAlign: 'middle', color: '#4caf50' }} />
                <span style={{ marginLeft: "4px", fontSize: 36, fontWeight: 'bold', color: '#0b2343' }}>Task approved.</span>
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: '16px', border: "2px solid #4caf50", padding: '16px', borderRadius: '8px', backgroundColor: '#fff' }}>
                <p style={{ fontSize: 20 }}><span style={{color: "#0b2343", fontWeight: "bold" }}>Task-ID:</span> {id}</p>
                <p style={{ fontSize: 20 }}><span style={{color: "#0b2343", fontWeight: "bold"}}>Task Name:</span> {name}</p>
                <p style={{ fontSize: 20 }}><span style={{color: "#0b2343", fontWeight: "bold"}}>Task Status:</span> {status}</p>
            </Box>
        </Box>
    );
};

export default SuccessfulApproved;
