import React from 'react';
import RoleComponent from '../../components/AccountsManagement/Role/RoleComponent';

const RoleManagement: React.FC = () => {


  return (
    <RoleComponent />

  );
};

export default RoleManagement;
