import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, FormGroup, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import InitialsComponent from '../Common/InitialsComponent'; // İlgili bileşenin doğru yolu
import { ThemeConfigModel } from '../../models/DataIntegration/ThemeConfig';
import { TenantModel } from '../../models/Tenant/Tenant';
import { Permission } from '../../models/Accounts/Permission';
import { Role } from '../../models/Accounts/Role';

// Yeni bir arayüz tanımlayın, burada firstname ve lastname'i belirtin
interface User {
  firstname: string;
  lastname: string;
  // Diğer alanlar burada belirtilebilir
}

interface DynamicFormProps<T> {
  initialValues: T;
  fields: Array<{ name: keyof T | string; label: string; type?: string; options?: string[]; isCustom?: boolean }>;
  onSave: (values: T) => void;
}

const DynamicForm = <T extends User | ThemeConfigModel | TenantModel | Permission | Role>({
  initialValues,
  fields,
  onSave,
}: DynamicFormProps<T>) => {
  const [values, setValues] = useState<T>(initialValues);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (name: keyof T | string, value: any) => {
    setValues({ ...values, [name as keyof T]: value });
  };

  const handleSubmit = () => {
    onSave(values);
  };

  const isUser = (values: any): values is User => {
    return 'firstname' in values && 'lastname' in values;
  };

  const renderField = (field: { name: keyof T | string; label: string; type?: string; options?: string[]; isCustom?: boolean }) => {
    if (field.isCustom) {
      return (
        <div key={field.name as string}>
          <label>{field.label}</label>
          {isUser(values) && (
            <InitialsComponent name={`${values.firstname || ''} ${values.lastname || ''}`} />
          )}
        </div>
      );
    } else if (field.type === 'checkbox') {
      return (
        <FormGroup key={field.name as string}>
          {field.options?.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={
                    Array.isArray(values[field.name as keyof T]) &&
                    (values[field.name as keyof T] as unknown as string[]).includes(option)
                  }
                  onChange={() =>
                    handleChange(
                      field.name,
                      Array.isArray(values[field.name as keyof T]) &&
                      (values[field.name as keyof T] as unknown as string[]).includes(option)
                        ? (values[field.name as keyof T] as unknown as string[]).filter((item: string) => item !== option)
                        : [...(values[field.name as keyof T] as unknown as string[] || []), option]
                    )
                  }
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      );
    } else if (field.type === 'select') {
      return (
        <TextField
          key={field.name as string}
          select
          label={field.label}
          value={values[field.name as keyof T] as unknown as string}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          margin="normal"
        >
          {field.options?.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      );
    } else if (field.type === 'password') {
      return (
        <TextField
          key={field.name as string}
          label={field.label}
          value={values[field.name as keyof T] as unknown as string}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    } else {
      return (
        <TextField
          key={field.name as string}
          label={field.label}
          value={values[field.name as keyof T] as unknown as string}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          margin="normal"
          type={field.type || 'text'}
        />
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {fields.map(field => renderField(field))}
      </Grid>

      <Grid container sx={{ marginTop: "15px" }}>
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => { console.log("close") }}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button variant="contained" style={{ backgroundColor: "#0b2343" }} onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DynamicForm;
