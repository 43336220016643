import React from "react";
import { Box } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

interface MailErrorProps {
    error: string;
}

const MailError: React.FC<MailErrorProps> = ({ error }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                backgroundColor: "#ffccc6",
                borderRadius: "16px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: "row",
                gap: "8px",
                width: "100%",
                maxWidth: "600px",
                margin: "0 auto",
                textAlign: "center", 
                marginBottom: "10px"
            }}
        >
            <ErrorIcon style={{color: "red", fontSize: 50}} />
            <p style={{ fontSize: 24, fontWeight: "bold", color: "#0b2343" }}>{error}</p>
        </Box>


    )
};

export default MailError;
