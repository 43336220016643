import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox, Link, IconButton, InputAdornment, Alert, AlertColor } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginPage, setLoginPage] = useState<boolean>(false);

  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [registerEmail, setRegisterEmail] = useState<string>("");
  const [registerPassword, setRegisterPassword] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [registerPasswordShow, setRegisterPasswordShow] = useState<boolean>(false);

  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState<AlertColor>('success');
  const { i18n } = useTranslation();
  const { t } = useTranslation('global');

  useEffect(() => {
    const lang = navigator.language;
    i18n.changeLanguage(lang === "tr" ? "tr" : "en")
  },[]);


  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    await login(email, password);
    navigate('/dashboard');
  };

  const handleRegister = async () => {
    const registerBody = {
      firstname,
      lastname,
      "email": registerEmail,
      "password": registerPassword,
      "role": "admin",
      company
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/register`, registerBody);
      console.log("register response -> ", response);
      setMessage(t('login.success_register'));
      setMessageType("success");
      setShowMessage(true);
    } catch (error) {
      console.log(error);
      setMessage(t('login.failed_register'));
      setMessageType("error");
      setShowMessage(true);
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 3000)

  };



  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const getMessage = () => {
    return (
      <Alert severity={messageType} sx={{ width: '100%' }}>
        {message}
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: "whitesmoke",
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: 3,
          }}
        >
          <img src="/assets/images/logo-light.png" alt="Planitsecure-Logo" style={{ height: 40, marginBottom: 8 }} />

          <Typography variant="h5" fontWeight="bold" color="#0b2343">
            {t('login.hi_welcome')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('login.enter_creadentials')}
          </Typography>
        </Box>

        <Box>
          {showMessage ? getMessage() : null}
        </Box>

        {!loginPage ? (

          <Box>
            <TextField
              label={t('login.email')}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => { setEmail(e.target.value) }}

            />
            <TextField
              label={t('login.password')}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginY: 2,
              }}
            >
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={t('login.keep_me')}
              />
              <Link href="#" color="primary" variant="body2">
                {t('login.forgot_password')}
              </Link>
            </Box>

            <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleLogin}
                disabled={email === "" || password === ""}
                sx={{
                  backgroundColor: "#102240",
                  width: "70%",
                }}
              >
                {t('login.sign_in')}
              </Button>
            </div>

            <Box sx={{ marginTop: 4 }}>
              <p style={{ textAlign: "center" }}>{t('login.dont_account')} <span style={{ color: "#0b2343", fontWeight: "bold", textAlign: "center", cursor: "pointer" }} onClick={() => { setLoginPage(!loginPage) }}>{t('login.register_now')}</span> </p>
            </Box>

          </Box>

        ) : (
          <Box>

            <Box>
              <IconButton onClick={() => { setLoginPage(!loginPage) }}>
                <KeyboardBackspaceIcon style={{ color: "#0b2343" }} />
              </IconButton>
              <span onClick={() => { setLoginPage(!loginPage) }} style={{ color: "#0b2343", fontWeight: "bold", cursor: "pointer" }}>{t('login.have_account')}</span>
            </Box>
            <Box>

              <TextField
                label={t('users.firstname')}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => { setFirstname(e.target.value) }}
              />

              <TextField
                label={t('users.lastname')}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => { setLastname(e.target.value) }}
              />

              <TextField
                label={t('users.company')}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => { setCompany(e.target.value) }}
              />

              <TextField
                label={t('users.email')}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => { setRegisterEmail(e.target.value) }}
              />

              <TextField
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                label={t('users.password')}
                margin="normal"
                type={registerPasswordShow ? "text" : "password"}
                fullWidth
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => { setRegisterPasswordShow(!registerPasswordShow) }}
                          edge="end"
                        >
                          {registerPasswordShow ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />


              <div style={{ justifyContent: "center", textAlign: "center", display: "flex", marginTop: 10 }}>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleRegister}
                  disabled={firstname === "" || lastname === "" || registerEmail === "" || registerPassword === "" || company === ""}
                  sx={{
                    backgroundColor: "#102240",
                    width: "70%",
                  }}
                >
                  {t('login.register')}
                </Button>
              </div>


            </Box>
          </Box>
        )}

      </Box>
    </Box>
  );
};

export default Login;
