import React, { useState } from "react";
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Button, TextField, Alert, AlertColor, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import { TaskManagementService } from "../../services/api";
import { useTranslation } from "react-i18next";


interface EmailPreviewProps {
    open: boolean;
    onClose: () => void;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({ open, onClose }) => {

    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");
    const [text, setText] = useState<string>("Test Mail");


    const [message, setMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState<AlertColor>('success');

    const { t } = useTranslation('global');


    const clear = () => {
        setFrom("");
        setTo("");
        setText("Test Mail");
    };


    const sendMail = async () => {
        try {
            let myMail = {
                from,
                to,
                text
            };
            const resp = await TaskManagementService.create(myMail, "smtp-config/send-test-mail");
            console.log("mail response -> ", resp);

            setMessage("Mail sent.");
            setMessageType('success');
        } catch {
            setMessage("Failed to send mail.");
            setMessageType('error');
        }

        setShowMessage(true);

        setTimeout(() => {
            setShowMessage(false);
        }, 4000);
    };


    const getMessage = () => {
        return (
            <Alert severity={messageType} sx={{ width: '40%' }}>
                {message}
            </Alert>
        );
    };



    //     const emailHTML = `
    //     <html lang="en">
    //         <head>
    //             <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //             <style>
    //                 body {
    //                     font-family: Arial, sans-serif;
    //                     background-color: #f4f4f4;
    //                     margin: 0;
    //                     padding: 0;
    //                 }
    //                 .email-container {
    //                     width: 100%;
    //                     max-width: 600px;
    //                     margin: 0 auto;
    //                     background-color: #ffffff;
    //                     border: 4px solid #0b2343;
    //                     padding: 20px;
    //                     box-sizing: border-box;
    //                 }
    //                 .header {
    //                     background-color: rgba(11, 35, 67, 0.9);
    //                     color: #ffffff;
    //                     padding: 10px;
    //                     text-align: center;
    //                 }
    //                 .header h1 {
    //                     margin: 0;
    //                     font-size: 24px;
    //                 }
    //                 .content {
    //                     margin-top: 20px;
    //                     color: #333;
    //                     line-height: 1.6;
    //                 }
    //                 .footer {
    //                     margin-top: 30px;
    //                     text-align: center;
    //                     font-size: 12px;
    //                     color: #006400;
    //                 }
    //                 .footer a {
    //                     color: #006400;
    //                     text-decoration: none;
    //                 }
    //             </style>
    //         </head>
    //         <body>
    //             <div class="email-container">
    //                 <div class="header">
    //                     <h1>Email Configuration</h1>
    //                 </div>
    //                 <div class="content">
    //                     <p>Hello,</p>
    //                     <p>This is a test email sent for verification purposes.</p>
    //                 </div>
    //                 <div class="footer">
    //                     <p>This email was sent for testing purposes only.</p>
    //                 </div>
    //             </div>
    //         </body>
    //     </html>
    // `;

    return (
        <Dialog
            open={open}
            onClose={() => { onClose(); clear(); }}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "auto", width: "auto" } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    {t('settings.email_conf_preview')}
                </DialogTitle>
                <IconButton onClick={() => { onClose(); clear(); }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>

            <DialogContent>

                <Grid container rowSpacing={3}>

                    <Grid size={12} container>
                        {showMessage && (
                            <Grid size={12} display="flex">
                                {getMessage()}
                            </Grid>
                        )}
                    </Grid>

                    <Grid size={12} container>
                        <Grid size={1} display="flex" justifyContent="center" alignItems="center">
                            <Typography style={{ color: "#0b2343", fontWeight: "bold" }}>
                                {t('settings.from')}
                            </Typography>
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                value={from}
                                onChange={(e) => setFrom(e.target.value)}
                                size="small"
                            />
                        </Grid>

                    </Grid>

                    <Grid size={12} container>

                        <Grid size={1} display="flex" justifyContent="center" alignItems="center">
                            <Typography style={{ color: "#0b2343", fontWeight: "bold" }}>
                                {t('settings.to')}
                            </Typography>
                        </Grid>

                        <Grid size={6}>
                            <TextField
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid size={12} >
                        <TextField
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            label={t('settings.mail_text')}
                            size="small"
                            multiline
                            rows={3}
                            fullWidth
                        />
                    </Grid>

                    <Grid size={12} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#006400" }}
                            disabled={!from || !to || !text}
                            onClick={sendMail}
                        >
                            {t('settings.send_test_mail')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>


        </Dialog>
    );
}

export default EmailPreview;
