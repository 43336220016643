import React from 'react';
import PermissionComponent from '../../components/AccountsManagement/Permission/PermissionComponent';

const PermissionManagement: React.FC = () => {


  return (
    <PermissionComponent />
  );
};

export default PermissionManagement;
