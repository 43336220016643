import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableHead, TableCell, TableRow, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Permission } from '../../../models/Accounts/Permission';
import { PermissionService } from '../../../services/api';
import { useTranslation } from 'react-i18next';
import SessionExpire from '../../Common/SessionExpire';


const PermissionComponent: React.FC = () => {

    const [allPermission, setAllPermission] = useState<Permission[]>([]);
    const { t } = useTranslation('global');
    const [session,setSession] = useState<boolean>(false);


    const getAllPermission = async () => {
        try{
            const response = await PermissionService.getAll();
            const permissions: Permission[] = response as Permission[];
            setAllPermission(permissions);
        }catch(error){
            console.log(error);
            setSession(true);
        }
    };

    useEffect(() => {
        getAllPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSessionCheck = () =>{
        setSession(false);
        window.location.href = '/login';
    };

    return (
        <Paper>
            <SessionExpire open={session} onClose={handleSessionCheck} />
            <Grid container size={12} sx={{ margin: 5 }}>
                <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                    {t('permissions.permission_management')}
                </Typography>
            </Grid>
            <Grid container sx={{ margin: 5 }} display="flex" direction="column">
                <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                    <TableHead style={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('permissions.name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('permissions.description')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('permissions.service')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('permissions.permission')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPermission && allPermission.length > 0 ? (
                            allPermission.map((permission, index) => (
                                <TableRow key={index}>
                                    <TableCell>{permission.name}</TableCell>
                                    <TableCell>{permission.description}</TableCell>
                                    <TableCell>
                                        {permission?.permissions?.length > 0 ? (
                                            permission.permissions.map((perm, index) => (
                                                <div key={index}>{perm.service}</div>
                                            ))
                                        ) : (
                                            t('permissions.no_data')
                                        )}

                                    </TableCell>
                                    <TableCell>
                                        {permission?.permissions?.map((perm, index) => (
                                            <div key={index}>
                                                {perm.actions.map((action, actionIndex) =>
                                                    actionIndex === perm.actions.length - 1 ? action : `${action}-`
                                                )}
                                            </div>
                                        )) ?? (t('permissions.no_data'))}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography align="center">{t('permissions.no_permission')}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Paper>
    )
}


export default PermissionComponent;
