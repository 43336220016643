import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

interface InitialsComponentProps {
    name: string;
}

const InitialsComponent: React.FC<InitialsComponentProps> = ({ name }) => {
    const getInitials = (name: string): string => {
        const nameParts = name.trim().split(' ');
        const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
        return initials;
    };

    const initials = getInitials(name);
    const [backgroundColor, setBackgroundColor] = useState('#0b2343'); // Default color
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

    // Function to set text color based on background color
    const getTextColor = (bgColor: string): string => {
        const r = parseInt(bgColor.slice(1, 3), 16);
        const g = parseInt(bgColor.slice(3, 5), 16);
        const b = parseInt(bgColor.slice(5, 7), 16);

        // Brightness calculation
        const brightness = (r * 0.299 + g * 0.587 + b * 0.114);
        return brightness > 186 ? '#000000' : '#FFFFFF'; // White or black
    };

    const textColor = getTextColor(backgroundColor); // Dynamic text color

    const avatarStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '10px',
        cursor: 'pointer',
        position: 'relative',
        float: 'left', // Ensure the avatar aligns to the left
    };

    const initialsStyle: React.CSSProperties = {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: backgroundColor,
        color: textColor, // Dynamic text color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
    };

    const colorPickerStyle: React.CSSProperties = {
        position: 'absolute',
        right: '-220px', // Position the color picker to the right of the avatar
        top: '0',
        zIndex: 2,
    };

    return (
        <div style={avatarStyle} onClick={() => setColorPickerVisible(!colorPickerVisible)}>
            <div style={initialsStyle}>{initials}</div>
            {colorPickerVisible && (
                <div style={colorPickerStyle}>
                    <SketchPicker
                        color={backgroundColor}
                        onChangeComplete={(color: { hex: string }) => {
                            setBackgroundColor(color.hex);
                            setColorPickerVisible(false); // Close picker after color selection
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default InitialsComponent;
