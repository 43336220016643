import React from 'react';
import { Permission } from '../../models/Accounts/Permission';
import DynamicForm from '../Common/DynamicForm';
import withEditMode from '../Common/withEditMode';

interface PermissionFormProps {
  item?: Permission | null;
  onSave: (permission: Permission) => void;
}

const PermissionForm: React.FC<PermissionFormProps> = ({ item, onSave }) => {
  const initialValues: Permission = item || { _id : "",name: '', description: '', permissions: [] };
  const fields = [
    { name: 'name' as keyof Permission, label: 'Permission Name' },
    { name: 'description' as keyof Permission, label: 'Permission Description' },
  ];

  return <DynamicForm initialValues={initialValues} fields={fields} onSave={onSave} />;
};

export default withEditMode(PermissionForm);
