import React from 'react';
import UserComponent from '../../components/AccountsManagement/User/UserComponent';

const UserManagement: React.FC = () => {

  
  return (
    <UserComponent />
  );
};

export default UserManagement;
