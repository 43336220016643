import React from 'react';
import TaskManagementTable from '../../components/TaskManagement/TaskManagementTable';

const TaskPano: React.FC = () => {
  return (
    <TaskManagementTable
    />
  );
};

export default TaskPano;