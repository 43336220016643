import React, { useEffect, useState } from "react";
import { Avatar, Box, IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { HexColorPicker } from "react-colorful";

interface UserAvatarProps {
    initialsText: string;
    initialsColor: string;
    editable: boolean;
    onTextChange?: (text: string) => void;
    onColorChange?: (color: string) => void;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ initialsText, initialsColor, editable, onTextChange, onColorChange }) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [text, setText] = useState<string>("");
    const [color, setColor] = useState<string>("");

    useEffect(() => {
        setText(initialsText);
        setColor(initialsColor);
    }, [initialsText, initialsColor]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar sx={{ backgroundColor: color, height: 40, width: 40, fontSize: "16px" }}>{text}</Avatar>
            {editable && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton onClick={() => setEdit(!edit)}>
                        <EditIcon />
                    </IconButton>
                    {edit && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <HexColorPicker
                                color={color}
                                onChange={(newColor) => { 
                                    setColor(newColor); 
                                    if (onColorChange) onColorChange(newColor); // Güvenli çağrı
                                }}
                            />
                            <TextField
                                value={text}
                                onChange={(e) => {
                                    const newText = e.target.value.toLocaleUpperCase();
                                    if (newText.length <= 3) {
                                        setText(newText);
                                        if (onTextChange) onTextChange(newText); // Güvenli çağrı
                                    }
                                }}
                                variant="outlined"
                                size="small"
                                style={{width: "20%"}}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default UserAvatar;
