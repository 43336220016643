import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { SwipeableDrawer, Typography, TextField, IconButton, Button, Box, Select, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SubRole } from "../../../models/Accounts/SubRole";
import { Permission } from "../../../models/Accounts/Permission";
import { PermissionService, RoleService } from "../../../services/api";
import { useTranslation } from 'react-i18next';


interface RoleDetailProps {
    open: boolean;
    onClose: () => void;
    type: string;
    myRole: SubRole | null;
}

const RoleDetail: React.FC<RoleDetailProps> = ({ open, onClose, myRole, type }) => {

    const [name, setName] = useState<string>("");
    const [selectedPermission, setSelectedPermission] = useState<string[]>([]);
    const [allPermission, setAllPermission] = useState<Permission[]>([]);
    const [role, setRole] = useState<SubRole | null>(null);
    const { t } = useTranslation('global');


    const getAllPermission = async () => {
        try {
            const response = await PermissionService.getAll();
            const permissions: Permission[] = response as Permission[];
            setAllPermission(permissions);
        }
        catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        setRole(myRole);
        setName(myRole?.name || "");
        setSelectedPermission(myRole?.permissions.map((permision) => permision.name) || []);
        if (open) {
            getAllPermission();
        }
    }, [myRole, type, open]);

    const createRole = async () => {
        if (type === "Create") {
            const newRole = {
                name: name,
                permissionNames: selectedPermission
            }
            try {
                const response = await RoleService.create(newRole);
                console.log(response);
            } catch (error) {
                console.log(error);
            }
            onClose();
            clearFields();
        } else {
            const updatedRole = {
                name: name,
                permissionNames: selectedPermission
            }
            try {
                if (role?._id) {
                    const response = await RoleService.update(role?._id, updatedRole);
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
            onClose();
            clearFields();
        }
    };

    const clearFields = () => {
        setName("");
        setSelectedPermission([]);
    };

    const handleFieldChange = (fieldName: string, newValue: any) => {
        const setters: Record<string, (value: any) => void> = {
            name: setName,
            selectedPermission: setSelectedPermission
        };

        const setter = setters[fieldName];
        if (setter) {
            setter(newValue);
        }
    };

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => {
                clearFields();
                onClose();
            }}
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {type === "Create" ? t('sub_roles.create_sub_role') : t('sub_roles.edit_role')}
                    </Typography>
                    <IconButton onClick={() => {
                        clearFields();
                        onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div>
                    <Typography variant="body1" sx={{ color: "#0b2343" }}>
                        {t('sub_roles.sub_role_details')}
                    </Typography>
                </div>

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>

                    <Grid size={12}>
                        <TextField
                            label={t('sub_roles.name')}
                            value={name || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("name", e.target.value) }}
                        />
                    </Grid>


                    <Grid size={12}>
                        <Select
                            value={selectedPermission}
                            fullWidth
                            onChange={(e) => handleFieldChange("selectedPermission", e.target.value)}
                            multiple
                        >
                            {allPermission.map((permission) => (
                                <MenuItem key={permission.name} value={permission.name}>
                                    {permission.name}
                                </MenuItem>
                            ))}
                        </Select>

                    </Grid>

                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        clearFields();
                        onClose();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                    }}
                    onClick={() => { createRole() }}
                >
                    {t('general.save')}
                </Button>
            </Box>

        </SwipeableDrawer>
    );
}

export default RoleDetail;
