import React, { useState, useEffect } from 'react';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { User } from '../../../models/Accounts/User';
import { UserService } from '../../../services/api';
import UserDetail from './UserDetail';
import DeleteModal from '../../Common/DeleteModal';
import UserAvatar from '../../Common/UserAvatar';
import { useTranslation } from "react-i18next";
import SessionExpire from '../../Common/SessionExpire';


const UserComponent: React.FC = () => {
    const [alUsers, setAllUsers] = useState<User[]>([]);
    const [userType, setUserType] = useState<string>('Create');
    const [userDetailOpen, setUserDetailOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [deletedItem, setDeletedItem] = useState<string>("");
    const [allEmail, setAllEmail] = useState<string[]>([]);
    const { t } = useTranslation('global');

    const [session, setSession] = useState<boolean>(false);


    const getAllUser = async () => {
        try {
            const response = await UserService.getAll();
            const users: User[] = response.data as User[];
            setAllUsers(users);
            setAllEmail(users.map((user) => user.email));
        }catch(error){
            console.log(error);
            setSession(true);
        }

    };

    useEffect(() => {
        getAllUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteUser = async () => {
        try {
            const response = await UserService.delete(deletedItem);
            console.log(response);
            getAllUser();
            setDeletedItem("");
            setDeleteModalOpen(!deleteModalOpen);
        } catch (error) {
            console.log(error)
            getAllUser();
            setDeleteModalOpen(!deleteModalOpen);
        }
    };

    const handleSessionCheck = () =>{
        setSession(false);
        window.location.href = '/login';
    };

    return (
        <Paper>
            <UserDetail
                open={userDetailOpen}
                onClose={() => {
                    setUserDetailOpen(!userDetailOpen);
                    getAllUser();
                }}
                myUser={selectedUser || null}
                type={userType}
                emailList={allEmail}
            />

            <DeleteModal
                open={deleteModalOpen}
                onClose={() => { setDeleteModalOpen(!deleteModalOpen) }}
                onResponse={(response) => {
                    if (response) {
                        deleteUser();
                    } else {
                        setDeletedItem("");
                        setDeleteModalOpen(!deleteModalOpen);
                    }
                }}
            />

            <SessionExpire
                open={session}
                onClose={handleSessionCheck}
            />
            <Grid container size={12} sx={{ margin: 5 }}>
                <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                    {t('users.users_managemet')}
                </Typography>
            </Grid>
            <Grid container sx={{ margin: 5 }} display="flex" direction="column">

                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => {
                        setUserType("Create");
                        setUserDetailOpen(!userDetailOpen);
                        setSelectedUser(null);
                    }
                    }>
                        {t('users.create_user')}
                    </Button>
                </Box>

                <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                    <TableHead style={{ backgroundColor: "whitesmoke" }}>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.initials')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.firstname')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.lastname')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.email')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.user_type')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.role')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.company')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('users.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alUsers && alUsers.length > 0 ? (
                            alUsers.map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <UserAvatar initialsText={user.initialsText} initialsColor={user.initialsColor} editable={false} />
                                    </TableCell>
                                    <TableCell>{user.firstname}</TableCell>
                                    <TableCell>{user.lastname}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role.name}</TableCell>
                                    <TableCell>
                                        {user.subrole.map((sub, index) => (
                                            <p key={index}>{sub.name}</p>
                                        ))}
                                    </TableCell>
                                    <TableCell>{user.company}</TableCell>
                                    <TableCell>
                                        <Box>
                                            <IconButton onClick={() => {
                                                setSelectedUser(user);
                                                setUserType("Update");
                                                setUserDetailOpen(!userDetailOpen);
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => { setDeletedItem(user._id); setDeleteModalOpen(!deleteModalOpen); }}>
                                                <DeleteIcon style={{ color: "red" }} />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography align="center">{t('users.no_user')}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Paper>
    )
};

export default UserComponent;