import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Card, CardContent, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SubtaskDetail from './SubtaskDetail';
import { TaskManagementService } from '../../../services/api';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Subtask, Task } from '../../../models/Task/Pano';
import TaskStatusField from './TaskStatusField';
import { useTranslation } from 'react-i18next';

interface SubtaskProps {
    subtasks: Subtask[];
    parentTask: Task | null;
    statusPermission: boolean;
    onRefresh: (refresh: boolean) => void;
}


const ParentTaskDetailSubtaskTable: React.FC<SubtaskProps> = ({ subtasks, parentTask, statusPermission, onRefresh }) => {

    const [subtaskList, setSubtaskList] = useState<Subtask[]>();
    const [subtaskOpen, setSubtaskOpen] = useState(false);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();
    const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState<number>(0);
    const [parent, setParent] = useState<Task | null>();
    const { t } = useTranslation('global');

    useEffect(() => {
        setSubtaskList(subtasks || []);
        setParent(parentTask);
    }, [subtasks, parentTask]);


    const subtaskOpenFun = () => {
        setSubtaskOpen(!subtaskOpen);
    };

    const updateSubtask = async (subtaskID: string, value: any) => {
        const parent = { ...parentTask };
        const allSubtask = parent.subtasks;

        const mySubtask = allSubtask?.find((sub) => sub.taskID === subtaskID);

        if (mySubtask) {
            mySubtask.status = value;

            const response = await TaskManagementService.update(mySubtask.taskID, mySubtask, "task/subtask");
            console.log("subtask status update response -> ", response);

            if (response) {
                // `subtaskList` içinde subtask'ı güncelle
                setSubtaskList((prevSubtaskList) =>
                    prevSubtaskList?.map((sub) =>
                        sub.taskID === subtaskID ? { ...sub, status: value } : sub
                    )
                );
            }
            onRefresh(true);
        } else {
            console.log("subtask status update error");
        }
    };

    const addSubtask = async (task: Task | null) => {

        let newSubtask = {
            task: '',
            responsible: null,
            status: 'Not Assigned',
            targetDate: null,
            response: '',
            priority: '',
            lastUpdate: new Date(),
            documents: [],
            comments: [],
            creationDate: new Date(),
        };
        if(task && task.taskID){
            try{
                const response = await TaskManagementService.create(newSubtask, "task/subtask/" + task.taskID)
                console.log("my subtask response -> ", response);
                onRefresh(true);
            }catch(error){
                console.log(error);
            }
        }
    };


    return (

        <Grid size={11} container display={"flex"} sx={{ marginTop: "20px" }}>
            <SubtaskDetail
                open={subtaskOpen}
                onClose={() => { subtaskOpenFun() }}
                subtaskID={selectedSubtask?.taskID || ""}
                parentTaskID={parent?.taskID || ""}
                subtaskIndex={selectedSubtaskIndex}
                onRefresh={(refresh) => {
                    if (refresh) {
                        onRefresh(true);
                    }
                }}
            />

            <Card square sx={{ minWidth: "33vw" }}>
                <div>
                    <IconButton onClick={()=>{addSubtask(parent || null)}} sx={{ color: '#0b2343', marginLeft: 2 }}>
                        <AddCircleIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                </div>
                <CardContent>
                    <Grid size={6} sx={{ minWidth: "33vw" }}>
                        <Table size="small">
                            <TableHead sx={{ backgroundColor: "whitesmoke", borderBottom: "1.5px black solid" }}>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>{t('task.subtask_id')}</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>{t('task.subtask')}</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>{t('task.status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subtaskList && subtaskList.length > 0 ? (subtaskList?.map((subtask, index) => (
                                    <TableRow key={index} >
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                            subtaskOpenFun();
                                            setSelectedSubtask(subtask);
                                            setSelectedSubtaskIndex(index);
                                        }}>
                                            <p>{subtask.taskID}</p>
                                        </TableCell>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                            subtaskOpenFun();
                                            setSelectedSubtask(subtask);
                                            setSelectedSubtaskIndex(index);
                                        }}>
                                            <p>{subtask.task}</p>
                                        </TableCell>
                                        <TableCell>
                                            <TaskStatusField
                                                item={subtask.status}
                                                onStatusChange={(newStatus) => { updateSubtask(subtask.taskID, newStatus) }}
                                                allStatus={subtask.possibleNextStatus.map((status) => status) || []}
                                                permission={statusPermission}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))) : <TableRow>
                                    <TableCell>
                                        <p>{t('task.no_subtask')}</p>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default ParentTaskDetailSubtaskTable;