import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

interface SessionExpireProps {
    open: boolean;
    onClose: () => void;
}

const SessionExpire: React.FC<SessionExpireProps> = ({open,onClose}) => {
    const {logout} = useAuth();
    const {t} = useTranslation('global');

    return(

        <Dialog open={open} onClose={()=>{logout(); onClose();}}>

            <DialogTitle>{t('general.session_title')}</DialogTitle>

            <DialogContent>
                <p>{t('general.session_message')}</p>
                <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={()=>{logout(); onClose();}}>{t('general.okay')}</Button>
            </DialogContent>
            

        </Dialog>

    )
}


export default SessionExpire