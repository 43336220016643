import React, { useState } from 'react';
import { Box, IconButton, FormControl, Select, MenuItem, Button } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';


interface TaskSortProps {
    onSortChange: (column: string, sort: string) => void;
}

const TaskSort: React.FC<TaskSortProps> = ({ onSortChange }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState("taskID");
    const [selectedSort, setSelectedSort] = useState("asc");
    const { t } = useTranslation('global');

    const columns = {
        "taskID": "Task ID",
        "refNum": "Ref Num",
        "requestDate": "Request Date",
        "responsible": "Responsible",
        "status": "Status",
        "creationDate": "Creation Date",
        "targetDate": "Target Date"
    };

    const sort = {
        "asc": "Ascending",
        "desc": "Descending"
    };

    const language: Record<string,string> = {
        "Ascending": t('task.ascending'),
        "Descending": t('task.descending'),
        "Task ID":  t('task.task_id'),
        "Ref Num":  t('task.ref_num'),
        "Request Date":  t('task.request_date'),
        "Responsible":  t('task.responsible'),
        "Status":  t('task.status'),
        "Creation Date":  t('task.creation_date'),
        "Target Date":  t('task.target_date'),
    };



    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const sortTask = () => {
        onSortChange(selectedColumns, selectedSort)
        setIsVisible(false);
    };

    const clearFilter = () => {
        setSelectedColumns("taskID");
        setSelectedSort("asc");
    };


    return (
        <div style={{ position: 'relative' }}>
            <IconButton onClick={handleButtonClick}>
                <SwapVertIcon />
            </IconButton>
            {isVisible && <Box
                sx={{
                    minWidth: '25vw',
                    minHeight: '10vw',
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderColor: 'grey.500',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50px',
                    zIndex: 1,
                    padding: '16px',
                    borderRadius: "10px",
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'
                }}
            >
                <IconButton
                    onClick={handleButtonClick}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 10,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Grid container columnSpacing={3} display="flex" alignItems="center" >
                    <Grid size={6} justifyContent="center">
                        <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                            <Select
                                value={selectedColumns}
                                onChange={(e) => setSelectedColumns(e.target.value)}

                            >
                                {Object.entries(columns).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {language[value]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={6} justifyContent="center">
                        <FormControl fullWidth size="small" sx={{ minWidth: 120 }}>
                            <Select
                                value={selectedSort}
                                onChange={(e) => setSelectedSort(e.target.value)}
                                displayEmpty
                            >
                                {Object.entries(sort).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {language[value]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid size={12} sx={{ marginTop: 4 }}>
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{
                                position: 'absolute',
                                bottom: 1,
                                left: 10,
                                margin: 2,
                                backgroundColor: "#c10101",
                                color: "white"
                            }}
                            onClick={() => clearFilter()}
                        >
                            {t('task.clear')}
                        </Button>

                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{
                                position: 'absolute',
                                bottom: 1,
                                right: 10,
                                marginTop: 3,
                                marginRight: 3,
                                marginBottom: 3,
                                backgroundColor: "#102240",
                                color: "white"
                            }}
                            onClick={() => sortTask()}
                        >
                            {t('task.sort')}
                        </Button>
                    </Grid>
                </Grid>


            </Box>
            }

        </div>
    )
}

export default TaskSort;