import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';


import { Project } from "../../models/Task/Pano";
import TaskProjectCreate from "./component/TaskProjectCreate";
import { TaskManagementService } from "../../services/api";
import DeleteModal from "../Common/DeleteModal";
import { User } from "../../models/Accounts/User";
import { UserService } from "../../services/api";
import { useTranslation } from "react-i18next";
import SessionExpire from "../Common/SessionExpire";

interface TaskProjectManagementTableProps {
    onProjectListChange: (allProject: Project[]) => void;
}

const TaskProjectManagementTable: React.FC<TaskProjectManagementTableProps> = ({ onProjectListChange }) => {

    const navigate = useNavigate();
    const [projectList, setProjectList] = useState<Project[]>([]);

    const [projectOpen, setProjectOpen] = useState<boolean>(false);
    const [projectOpenType, setProjectOpenType] = useState<string>("");
    const [selectedProject, setSelectedProject] = useState<Project | null>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedCode, setSelectedCode] = useState<string | null>(null);
    const [userList, setUserList] = useState<User[]>([]);
    const { t } = useTranslation('global');
    const [session,setSession] = useState<boolean>(false);


    const getAllProject = async () => {
        try {
            const response = await TaskManagementService.getAll("project");
            if (!Array.isArray(response) || response.length === 0) {
                console.log("No projects found.");
                setProjectList([]);
                onProjectListChange([]);
                return;
            }
    
            const allProject: Project[] = response as Project[];
            const reversedProjectList = [...allProject].reverse();
            setProjectList(reversedProjectList);
            onProjectListChange(reversedProjectList);
        } catch (error) {
            console.log("Error fetching projects:", error);
            setSession(true);
        }
    };
    
    

    useEffect(() => {
        getAllProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const myFilter = {
                    page: 1,
                    limit: 1000,
                    sort: "firstname",
                    order: "asc",
                };
                const users = await UserService.getAllWithParam(myFilter, "filter");
                setUserList(users.users || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUsers();
    }, []);

    const redirectToTask = (projectCode: string) => {
        navigate("/task-management/project-tasks/" + projectCode);
    };

    const deleteProject = async () => {
        if (selectedCode) {
            const response = await TaskManagementService.delete(selectedCode, "project");
            console.log("project delete response -> ", response);
        }
        getAllProject();
    };

    const handleSessionCheck = () =>{
        setSession(false);
        window.location.href = '/login';
    };

    return (
        <Paper>
            <DeleteModal
                open={deleteModalOpen}
                onClose={() => { setDeleteModalOpen(!deleteModalOpen) }}
                onResponse={(response) => {
                    if (response) {
                        deleteProject();
                        setDeleteModalOpen(!deleteModalOpen);
                    } else {
                        setSelectedCode(null);
                        setDeleteModalOpen(!deleteModalOpen);
                    }
                }}
            />
            <SessionExpire open={session} onClose={handleSessionCheck} />
            <TaskProjectCreate
                open={projectOpen}
                onClose={() => {
                    setProjectOpen(!projectOpen);
                    getAllProject();
                }}
                project={selectedProject || null}
                type={projectOpenType}
            />

            <Grid container size={12} sx={{ margin: "15px" }}>
                <Typography variant="h4" sx={{ color: "#0b2343" }}>
                    {t('project.project_management')}
                </Typography>
            </Grid>

            <Grid container sx={{ margin: "30px" }} display="flex" direction="column">
                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => {
                        setProjectOpenType("Create");
                        setProjectOpen(!projectOpen);
                        setSelectedProject(null);
                    }
                    }>
                        {t('project.create_project')}
                    </Button>
                </Box>

                <Grid container size={12}>
                    <Table>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.project_id')}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.name')}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.coordinator')}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.independent_auditor')}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.create_date')}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {t('project.actions')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectList && projectList.length > 0 ? (
                                projectList.map((project, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => { redirectToTask(project.code) }}>{project.code}</TableCell>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => { redirectToTask(project.code) }}>{project.name}</TableCell>
                                        <TableCell
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { redirectToTask(project.code) }}
                                        >
                                            {(() => {
                                                const user = userList.find((user) => user._id === project.coordinator);
                                                return user ? `${user.firstname} ${user.lastname}` : "Unknown User";
                                            })()}
                                        </TableCell>
                                        <TableCell
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { redirectToTask(project.code) }}
                                        >
                                            {(() => {
                                                const user = userList.find((user) => user._id === project.independentAuditor);
                                                return user ? `${user.firstname} ${user.lastname}` : "Unknown User";
                                            })()}
                                        </TableCell>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => { redirectToTask(project.code) }}>{project.creationDate ? new Date(project.creationDate).toISOString().split("T")[0] : null}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => {
                                                    setSelectedProject(project);
                                                    setProjectOpenType("Edit");
                                                    setProjectOpen(!projectOpen);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { setDeleteModalOpen(!deleteModalOpen); setSelectedCode(project.code) }}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography align="center">{t('project.no_project')}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default TaskProjectManagementTable;
