import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

interface TaskStatusFieldProps {
    item: string;
    onStatusChange: (newStatus: string) => void;
    allStatus: string[];
    permission: boolean;
}

const TaskStatusField: React.FC<TaskStatusFieldProps> = ({ item, onStatusChange, allStatus, permission }) => {
    const [taskStatus, setTaskStatus] = useState<string>('');
    const [statusList, setStatusList] = useState<string[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const sortedList = ["Cancelled", "Not Assigned", "To Do", "In Progress", "Awaiting Approver", "Awaiting Int. Auditor", "Submitted", "Done"];

    useEffect(() => {
        setTaskStatus(item || '');
        setDisabled(permission);

        const sortedStatusList = [...allStatus, item].sort((a, b) => {
            return sortedList.indexOf(a) - sortedList.indexOf(b);
        });

        if (taskStatus && !sortedStatusList.includes(taskStatus)) {
            const taskStatusIndex = sortedList.indexOf(taskStatus);
            sortedStatusList.splice(taskStatusIndex, 0, taskStatus);
        }

        setStatusList(sortedStatusList);

    }, [item, allStatus, permission, taskStatus]);

    const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        if (value === "Done") {
            return { backgroundColor: "#377d51", color: "#FFFFFF" };
        } else if (value === "Not Assigned") {
            return { backgroundColor: "#F0F0F0", color: "#000000" };
        } else if (value === "Cancelled") {
            return { backgroundColor: "#c10101", color: "#FFFFFF" };
        } else if (value === "In Progress") {
            return { backgroundColor: "#FFFACD", color: "#000000" };
        } else if (value === "Awaiting Int. Auditor") {
            return { backgroundColor: "#f3b056", color: "#000000" };
        } else if (value === "Submitted") {
            return { backgroundColor: "#5ac57b", color: "#FFFFFF" };
        } else if (value === "Awaiting Approver") {
            return { backgroundColor: "#F0E68C", color: "#000000" };
        } else if (value === "To Do") {
            return { backgroundColor: "#EEE8AA", color: "#000000" };
        }
        return undefined;
    };

    const language: Record<string, string> = {
        "Cancelled": t('task.cancelled'),
        "Not Assigned": t('task.not_assigned'),
        "To Do": t('task.to_do'),
        "In Progress": t('task.in_progress'),
        "Awaiting Approver": t('task.awaiting_approver'),
        "Awaiting Int. Auditor": t('task.awaiting_int_auditor'),
        "Submitted": t('task.submitted'),
        "Done": t('task.done'),
    };

    const currentStatusIndex = sortedList.indexOf(taskStatus);

    return (
        <div>
            {!disabled ? (
                <Select
                    fullWidth
                    value={taskStatus}
                    label="Task Status"
                    onChange={(e) => {
                        setTaskStatus(e?.target.value);
                        onStatusChange(e?.target.value);
                    }}
                    style={getColor(taskStatus)}
                    size='small'
                    IconComponent={() => null}
                >
                    {
                        statusList.map((status, index) => {
                            const isBeforeCurrentStatus = sortedList.indexOf(status) < currentStatusIndex;
                            const isAfterCurrentStatus = sortedList.indexOf(status) > currentStatusIndex;

                            return (
                                <MenuItem key={index} value={status} style={getColor(status)}>
                                    {language[status]}
                                    {isBeforeCurrentStatus && <KeyboardDoubleArrowLeftIcon fontSize="small" style={{ marginRight: '5px' }} />}
                                    {isAfterCurrentStatus && <KeyboardDoubleArrowRightIcon fontSize="small" style={{ marginLeft: '5px' }} />}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            ) : (
                <MenuItem
                    key={-1}
                    value={taskStatus}
                    style={getColor(taskStatus)}
                    sx={{
                        borderRadius: "5px",
                    }}
                >
                    {language[taskStatus]?.length > 12
                        ? `${language[taskStatus].substring(0, 12)}...`
                        : language[taskStatus]}
                </MenuItem>
            )}
        </div>
    );
};

export default TaskStatusField;
