import React from "react";
import TenantComponent from "../components/Tenant/TenantComponent";

const Tenant: React.FC = () => {

    return(
        <TenantComponent />
    )

}


export default Tenant;