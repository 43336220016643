import React, { useEffect, useState } from "react";
import { Table, TableBody, TableRow, TableCell, Avatar, MenuItem, Tooltip, Box } from "@mui/material";
import { History } from "../../../models/Task/Pano";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Subtask, Document, Comment } from "../../../models/Task/Pano";
import { UserService } from "../../../services/api";
import { User } from "../../../models/Accounts/User";
import { useTranslation } from "react-i18next";

interface TaskHistoryProps {
    taskHistory: History[];
}

const TaskHistory: React.FC<TaskHistoryProps> = ({ taskHistory }) => {
    const [allHistory, setAllHistory] = useState<History[]>([]);
    const [userList, setUserList] = useState<User[]>([]);
    const {t} = useTranslation('global');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const myFilter = {
                    page: 1,
                    limit: 1000,
                    sort: "firstname",
                    order: "asc",
                };
                const users = await UserService.getAllWithParam(myFilter, "filter");
                setUserList(users.users || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUsers();
    }, []);


    const fields: { [key: string]: string } = {
        "task": t('task.task'),
        "responsible": t('task.responsible'),
        "regulationRef": t('task.regulation'),
        "status": t('task.status'),
        "priority": t('task.priority'),
        "comments": t('task.comments'),
        "approver": t('task.approver'),
        "internalAuditor": t('task.internal_auditor'),
        "subtasks": t('task.subtask'),
        "response": t('task.response'),
        "documents": t('task.docs'),
        "responsible.valueOf": t('task.responsible'),
        "approver.valueOf": t('task.approver'),
        "internalAuditor.valueOf": t('task.internal_auditor'),
        "targetDate": t('task.target_date'),
    };

    const statusColors = [
        { value: "Done", backgroundColor: "#377d51", color: "#FFFFFF" },
        { value: "Not Assigned", backgroundColor: "#F0F0F0", color: "#000000" },
        { value: "Cancelled", backgroundColor: "#c10101", color: "#FFFFFF" },
        { value: "In Progress", backgroundColor: "#FFFACD", color: "#000000" },
        { value: "Awaiting Int. Auditor", backgroundColor: "#f3b056", color: "#000000" },
        { value: "Submitted", backgroundColor: "#5ac57b", color: "#FFFFFF" },
        { value: "Awaiting Approver", backgroundColor: "#F0E68C", color: "#000000" },
        { value: "To Do", backgroundColor: "#EEE8AA", color: "#000000" }
    ];

    const priorityColors = [
        { value: "Critical", backgroundColor: "#3b198e", color: "white" },
        { value: "High", backgroundColor: "#3a83bb", color: "white" },
        { value: "Medium", backgroundColor: "#5558d5", color: "white" },
        { value: "Low", backgroundColor: "#6799f5", color: "white" }
    ];

    const priorityLanguage: Record<string,string> = {
        "Low": t('task.low'),
        "Medium": t('task.medium'),
        "High":  t('task.high'),
        "Critical":  t('task.critical'),
    };

    const statusLanguage: Record<string,string> = {
        "Cancelled": t('task.cancelled'),
        "Not Assigned": t('task.not_assigned'),
        "To Do":  t('task.to_do'),
        "In Progress":  t('task.in_progress'),
        "Awaiting Approver":  t('task.awaiting_approver'),
        "Awaiting Int. Auditor":  t('task.awaiting_int_auditor'),
        "Submitted":  t('task.submitted'),
        "Done":  t('task.done'),
    };

    useEffect(() => {
        setAllHistory(taskHistory);
    }, [taskHistory]);

    const getType = (type: string) => {
        if (type === "created") return t('task.task_created');
        if (type === "updated") return t('task.task_updated');
        if (type === "subtask added") return t('task.subtask_added');
        if (type === "file upload to task") return t('task.file_upload_to_task');
        if (type === "file upload to subtask") return t('task.file_upload_to_subtask');
        if (type === "subtask deleted") return t('task.subtask_removed');
    };

    const getDate = (date: Date | string | null): string => {
        if (!date) {
            return t('task.no_date');
        }
        const parsedDate = date instanceof Date ? date : new Date(date);

        if (isNaN(parsedDate.getTime())) {
            return t('task.invalid_date');
        }
        const today = new Date();
        const todayUTC = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const dateUTC = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
        const dayDiff = Math.floor((todayUTC.getTime() - dateUTC.getTime()) / (1000 * 60 * 60 * 24));
        if (dayDiff >= 30) {
            const monthDiff = Math.floor(dayDiff / 30);
            return `${monthDiff} ${t('task.month_ago')}`;
        } else if (dayDiff === 0) {
            return t('task.today')
        } else {
            return `${dayDiff} ${t('task.day_ago')}`;
        }
    };

    const getStatusColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        const status = statusColors.find(item => item.value === value);
        return status ? { backgroundColor: status.backgroundColor, color: status.color } : undefined;
    };

    const getPriorityColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        const priority = priorityColors.find(item => item.value === value);
        return priority ? { backgroundColor: priority.backgroundColor, color: priority.color } : undefined;
    };


    const getAvatar = (owner: string) => {
        if (owner) {
            const selectedUser = userList.filter((user) => user._id === owner)[0];

            if (!selectedUser) {
                return (
                    <Box display="flex" justifyContent="center">
                        <Avatar sx={{ backgroundColor: "#6abc45" }}></Avatar>
                    </Box>
                );
            }
            const color = selectedUser.initialsColor;
            return (
                <Box display="flex" justifyContent="center">
                    <Tooltip title={`${selectedUser.firstname} ${selectedUser.lastname}`}>
                        <Avatar sx={{ backgroundColor: color }}>
                            {selectedUser.initialsText}
                        </Avatar>
                    </Tooltip>
                </Box>
            );

        } else {
            return (
                <Box display="flex" justifyContent="center">
                    <Avatar sx={{ backgroundColor: "#6abc45" }}></Avatar>
                </Box>
            );
        }
    };




    const decodeUTF8 = (input: string) => {
        const byteArray = new Uint8Array(input.split('').map(char => char.charCodeAt(0)));
        const decoder = new TextDecoder("utf-8");
        return decoder.decode(byteArray);
    };


    const getKey = (history: History) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const key = history.changes[0].key;
            if (fields[key]) {
                return fields[key];
            } else if (key.includes("subtask")) {
                return subtaskType(history);
            } else if (key.includes("document")) {
                return documentType(history);
            } else if (key.includes("comment")) {
                return commentType(history);
            }
        }
        return "-";
    };

    const commentType = (history: History) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const type = history.changes[0].type;
            if (type === "added") {
                return t('task.comment_added');
            } else if (type === "removed") {
                return t('task.comment_removed');
            }

        } else {
            return t('task.comments');
        }

    };

    const documentType = (history: History) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const type = history.changes[0].type;
            if (type === "added") {
                return t('task.document_added');
            } else if (type === "removed") {
                return t('task.comment_removed');
            }

        } else {
            return t('task.docs');
        }
    };

    const subtaskType = (history: History) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            if (history.type === "file-upload-to-subtask") {
                return t('task.file_upload')
            } else {
                const type = history.changes[0].type;
                if (type === "added") {
                    if ((history.changes[0].newValue as Document).fileName) {
                        return t('task.subtask_file_added');
                    } else if ((history.changes[0].newValue as Comment).comment) {
                        return t('task.subtask_comment_added');
                    } else if ((history.changes[0].newValue as Subtask).taskID) {
                        return t('task.subtask_added');
                    }
                    return "subtask added type error";
                } else if (type === "removed") {
                    if ((history.changes[0].oldValue as Document).fileName) {
                        return t('task.subtask_file_removed');
                    } else if ((history.changes[0].oldValue as Comment).comment) {
                        return t('task.subtask_comment_removed');
                    } else if ((history.changes[0].oldValue as Subtask).taskID) {
                        return t('task.subtask_removed');
                    }
                } else if (type === "changed") {
                    if (history.changes[0].key.includes("status")) return t('task.subtask_status_changed');
                    else if (history.changes[0].key.includes("responsible")) return t('task.subtask_responsible_changed');
                    else if (history.changes[0].key.includes("priority")) return t('task.subtask_priority_changed');
                    else if (history.changes[0].key.includes("task")) return t('task.subtask_name_changed');
                    else if (history.changes[0].key.includes("response")) return t('task.subtask_response_changed');
                    else return t('task.subtask_changed');
                }
            }
        } else {
            return t('task.subtask');
        }
    };

    const getComment = (history: History, value: string) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const type = history.changes[0].type;
            if (type === "added" && value === "new") {
                return getText((history.changes[0].newValue as Comment).comment);
            } else if (type === "removed" && value === "old") {
                return getText((history.changes[0].oldValue as Comment).comment);
            } else {
                return getText("-");
            }
        } else {
            return getText("-");
        }
    };

    const getDocument = (history: History, value: string) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const type = history.changes[0].type;
            if (type === "added" && value === "new") {
                return getText(decodeUTF8((history.changes[0].newValue as Document).fileName));
            } else if (type === "removed" && value === "old") {
                return getText(decodeUTF8((history.changes[0].oldValue as Document).fileName));
            } else {
                return getText("-");
            }
        } else {
            return getText("-");
        }
    };

    const getSubtask = (history: History, value: string) => {
        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const type = history.changes[0].type;
            if (type === "added" && value === "new") {
                if (history.changes[0].key.includes("document")) {
                    return getText(decodeUTF8((history.changes[0].newValue as Document).fileName));
                } else if ((history.changes[0].newValue as Subtask).taskID) {
                    return getText((history.changes[0].newValue as Subtask).taskID);
                } else if ((history.changes[0].newValue as Comment).comment) {
                    return getText((history.changes[0].newValue as Comment).comment);
                } else {
                    return "subtask added error";
                }
            } else if (type === "removed" && value === "old") {
                if (history.type === "updated") {
                    if (history.changes[0].key.includes("document")) {
                        return getText(decodeUTF8((history.changes[0].oldValue as Document).fileName));
                    } else if ((history.changes[0].oldValue as Subtask).taskID) {
                        return getText((history.changes[0].oldValue as Subtask).taskID);
                    } else if ((history.changes[0].oldValue as Comment).comment) {
                        return getText((history.changes[0].oldValue as Comment).comment);
                    } else {
                        return "subtask removed error";
                    }
                } else {
                    return (history.changes[0].oldValue as Subtask).taskID;
                }
            } else if (type === "changed") {
                if (history.changes[0].key.includes("responsible")) {
                    return value === "new" ? getAvatar(history.changes[0].newValue as string) : value === "old" ? getAvatar(history.changes[0].oldValue as string) : getText("-");
                } else if (history.changes[0].key.includes("status")) {
                    return value === "new" ? getStatus(history.changes[0].newValue as string) : value === "old" ? getStatus(history.changes[0].oldValue as string) : getText("-");
                } else if (history.changes[0].key.includes("priority")) {
                    return value === "new" ? getPriority(history.changes[0].newValue as string) : value === "old" ? getPriority(history.changes[0].oldValue as string) : getText("-");
                } else {
                    return value === "new" ? getText(history.changes[0].newValue as string) : value === "old" ? getText(history.changes[0].oldValue as string) : getText("-");
                }
            } else {
                return getText("-");
            }
        } else {
            return getText("-");
        }
    };


    const getText = (text: string) => {
        if (text && text.length > 15) {
            return (
                <Tooltip title={text}>
                    <span style={{ color: "#0b2343", fontWeight: "bold" }}>
                        {text.substring(0, 15) + "..."}
                    </span>
                </Tooltip>
            )
        } else if (!text) {
            return (
                <span style={{ color: "#0b2343", fontWeight: "bold" }}>
                    {"-"}
                </span>
            )
        }
        else {
            return (
                <span style={{ color: "#0b2343", fontWeight: "bold" }}>
                    {text !== "" ? text : "-"}
                </span>
            )
        }
    };

    const getStatus = (value: string) => {
        return (
            <MenuItem sx={{ ...getStatusColor(value), ':hover': getStatusColor(value) }}>{statusLanguage[value]}</MenuItem>
        )
    };

    const getPriority = (value: string) => {
        return (
            value !== "" ? <MenuItem sx={{ ...getPriorityColor(value), ':hover': getPriorityColor(value) }}>{priorityLanguage[value]}</MenuItem> : getText("-")
        )
    };

    const getNewValue = (history: History) => {
        const basicField = ["task", "responsible", "regulationRef", "status", "priority", "response", "approver", "internalAuditor", "targetDate"];

        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const key = history.changes[0].key;

            if (basicField.includes(key)) {
                const newValue = history.changes[0].newValue as string;
                if (key === "responsible" || key === "approver" || key === "internalAuditor") {
                    return (getAvatar(newValue));
                } else if (key === "status") {
                    return (getStatus(newValue));
                } else if (key === "priority") {
                    return (getPriority(newValue));
                } else if (key === "targetDate") {
                    return (newValue ? (getText(newValue.split('T')[0])) : "-");
                } else {
                    return (newValue !== "" ? getText(newValue) : getText("-"));
                }
            } else if (key.includes("subtask")) {
                return getSubtask(history, "new");
            } else {
                if (key.includes("document")) {
                    return getDocument(history, "new");
                } else if (key.includes("comment")) {
                    return getComment(history, "new");
                }
            }
        } else {
            return getText("-");
        }
    };


    const getOldValue = (history: History) => {
        const basicField = ["task", "responsible", "regulationRef", "status", "priority", "response", "approver", "internalAuditor", "targetDate"];

        if (history.changes && history.changes.length > 0 && history.changes[0].key) {
            const key = history.changes[0].key;

            if (basicField.includes(key)) {
                const oldvalue = history.changes[0].oldValue as string;
                if (key === "responsible" || key === "approver" || key === "internalAuditor") {
                    return (getAvatar(oldvalue));
                } else if (key === "status") {
                    return (getStatus(oldvalue));
                } else if (key === "priority") {
                    return (getPriority(oldvalue));
                } else if (key === "targetDate") {
                    return (oldvalue ? (getText(oldvalue.split('T')[0])) : "-");
                } else {
                    return (oldvalue !== "" ? getText(oldvalue) : getText("-"));
                }
            } else if (key.includes("subtask")) {
                return getSubtask(history, "old");
            } else {
                if (key.includes("document")) {
                    return getDocument(history, "old");
                } else if (key.includes("comment")) {
                    return getComment(history, "old");
                }
            }
        } else {
            return getText("-");
        }
    };


    return (
        <>
            <Table sx={{ width: "100%", marginTop: "3vh" }}>
                <TableBody>
                    {allHistory.map((history, index) => (
                        <TableRow key={history.id}>
                            <TableCell sx={{ paddingX: "5px" }}>
                                {getText(String(index + 1))}
                            </TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>
                                <span style={{ color: "#0b2343", fontWeight: "bold" }}>{getType(history.operationType)}</span>
                            </TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>
                                <span style={{ color: "#0b2343", fontWeight: "bold" }}>{getDate(history.date)}</span>
                            </TableCell>
                            <TableCell sx={{ paddingX: "10px" }}>
                                {getAvatar(history.changedBy)}
                            </TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>{<span style={{ color: "#0b2343", fontWeight: "bold" }}>{getKey(history)}</span>}</TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>{getOldValue(history)}</TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>
                                <KeyboardDoubleArrowRightIcon />
                            </TableCell>
                            <TableCell sx={{ paddingX: "5px" }}>{getNewValue(history)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default TaskHistory;
