import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';



const Navbar: React.FC = () => {
    const [minHeight, setMinHeight] = useState<string>('10%');
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation('global');


    const updateMinHeight = () => {
        const width = window.innerWidth;

        if (width < 1200) {
            setMinHeight('9%');
        } else if (width >= 1200 && width < 1350) {
            setMinHeight('8.75%');
        } else if (width >= 1350 && width < 1500) {
            setMinHeight('8.25%');
        } else if (width >= 1500 && width < 1650) {
            setMinHeight('7.75%');
        } else if (width >= 1650 && width < 1800) {
            setMinHeight('7.25%');
        } else if (width >= 1800 && width < 2400) {
            setMinHeight('6.75%');
        } else {
            setMinHeight('5%');
        }
    };

    const redirectLogout = () => {
        logout();
        navigate('/login');
    };

    const redirectProfile = () => {
        navigate("/profile");
    };


    useEffect(() => {
        updateMinHeight();
        window.addEventListener('resize', updateMinHeight);
        return () => {
            window.removeEventListener('resize', updateMinHeight);
        };
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "whitesmoke",
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1000,
                height: "auto",
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '0 16px',
                minHeight: minHeight,
            }}
        >
            <LanguageSelector />
            <Tooltip title={t('profile.profile')}>
                <IconButton onClick={redirectProfile} sx={{ color: '#0b2343' }}>
                    <PersonIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('general.logout')}>
                <IconButton sx={{ color: '#0b2343' }} onClick={redirectLogout}>
                    <ExitToAppIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default Navbar;
