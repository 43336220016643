import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { TaskManagementService } from '../services/api';
import { useTranslation } from "react-i18next";


interface Project {
  id: string;
  name: string;
  code: string;
  taskCounter: number;
  tasks?: any[];
}

Chart.register(ArcElement, Tooltip, Legend);

const Dashboard: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation('global');


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const projectsData = await TaskManagementService.getAll('project');
        const projectsWithTasks = await Promise.all(
          projectsData.map(async (project: Project) => {
            const projectTasks = await TaskManagementService.getAllWithParam({ "conditions": [] }, `task/read-all/${project.code}`);
            return { ...project, tasks: projectTasks };
          })
        );
        setProjects(projectsWithTasks);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to fetch projects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
    if (value === "Done") {
      return { backgroundColor: "#377d51", color: "#FFFFFF" };
    } else if (value === "Not Assigned") {
      return { backgroundColor: "#D3D3D3", color: "#000000" };
    } else if (value === "Cancelled") {
      return { backgroundColor: "#A52A2A", color: "#FFFFFF" };
    } else if (value === "In Progress") {
      return { backgroundColor: "#FFD700", color: "#000000" };
    } else if (value === "Awaiting Int. Auditor") {
      return { backgroundColor: "#8B4513", color: "#000000" };
    } else if (value === "Submitted") {
      return { backgroundColor: "#5ac57b", color: "#FFFFFF" };
    } else if (value === "Awaiting Approver") {
      return { backgroundColor: "#F0E68C", color: "#000000" };
    } else if (value === "To Do") {
      return { backgroundColor: "#EEE8AA", color: "#000000" };
    }
    return undefined;
  };

  const generateStatusData = (tasks: any[]) => {
    const statusCounts: { [status: string]: number } = {};
    tasks.forEach(task => {
      const status = task.status || 'Not Assigned';
      statusCounts[status] = (statusCounts[status] || 0) + 1;
    });

    const backgroundColors = Object.keys(statusCounts).map(
      status => getColor(status)?.backgroundColor || '#D3D3D3'
    );

    return {
      labels: Object.keys(statusCounts),
      datasets: [
        {
          label: 'Tasks by Status',
          data: Object.values(statusCounts),
          backgroundColor: backgroundColors,
        },
      ],
    };
  };

  const generateTargetDateData = (tasks: any[]) => {
    interface TargetDateCounts {
      '0-1 Days': number;
      '1-3 Days': number;
      '3-7 Days': number;
      '7-30 Days': number;
      '30+ Days': number;
      'Not Assigned': number;
    }

    const targetDateCounts: TargetDateCounts = {
      '0-1 Days': 0,
      '1-3 Days': 0,
      '3-7 Days': 0,
      '7-30 Days': 0,
      '30+ Days': 0,
      'Not Assigned': 0,
    };

    tasks.forEach(task => {
      const targetDate = task.targetDate;
      const daysLeft = targetDate ? Math.ceil((new Date(targetDate).getTime() - Date.now()) / (1000 * 3600 * 24)) : null;

      if (daysLeft === null) {
        targetDateCounts['Not Assigned'] += 1;
      } else if (daysLeft <= 1) {
        targetDateCounts['0-1 Days'] += 1;
      } else if (daysLeft <= 3) {
        targetDateCounts['1-3 Days'] += 1;
      } else if (daysLeft <= 7) {
        targetDateCounts['3-7 Days'] += 1;
      } else if (daysLeft <= 30) {
        targetDateCounts['7-30 Days'] += 1;
      } else {
        targetDateCounts['30+ Days'] += 1;
      }
    });

    const totalTasks = Object.values(targetDateCounts).reduce((acc, count) => acc + count, 0);
    if (totalTasks === 0) {
      return {
        labels: ['Not Assigned'],
        datasets: [
          {
            label: 'Tasks by Target Dates',
            data: [1],
            backgroundColor: ['#D3D3D3'],
          },
        ],
      };
    }

    const labels = Object.keys(targetDateCounts) as (keyof TargetDateCounts)[];
    const data = labels.map(label => targetDateCounts[label]);

    console.log('Target Date Counts:', targetDateCounts);
    console.log('Data:', data);

    const backgroundColors = labels.map(label => {
      switch (label) {
        case '0-1 Days':
          return '#A52A2A';
        case '1-3 Days':
          return '#8B4513';
        case '3-7 Days':
          return '#FFD700';
        case '7-30 Days':
          return '#EEE8AA';
        case '30+ Days':
        case 'Not Assigned':
          return '#D3D3D3';
        default:
          return '#FFFFFF';
      }
    });

    return {
      labels: labels.filter(label => targetDateCounts[label] > 0),
      datasets: [
        {
          label: 'Tasks by Target Dates',
          data: data.filter(count => count > 0),
          backgroundColor: backgroundColors.filter((color, index) => targetDateCounts[labels[index]] > 0),
        },
      ],
    };
  };

  const generateUserData = (tasks: any[]) => {
    const userCounts: { [key: string]: number } = {};

    tasks.forEach((task) => {
      const user = task.responsible || 'Not Assigned';
      userCounts[user] = (userCounts[user] || 0) + 1;
    });

    const sortedUsers = Object.entries(userCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 4);

    const topUsersData = sortedUsers.map(([user, count]) => ({
      user,
      count,
    }));

    const totalCount = Object.values(userCounts).reduce((acc, count) => acc + count, 0);
    const topFourCount = topUsersData.reduce((acc, { count }) => acc + count, 0);
    const othersCount = totalCount - topFourCount;

    if (othersCount > 0) {
      topUsersData.push({ user: 'Others', count: othersCount });
    }

    const backgroundColors = topUsersData.map(userData =>
      userData.user === 'Not Assigned' || userData.user === 'Others' ? '#D3D3D3' : ['#A52A2A', '#8B4513', '#FFD700', '#EEE8AA'][topUsersData.indexOf(userData) % 4]
    );

    return {
      labels: topUsersData.map(userData => userData.user),
      datasets: [
        {
          label: 'Pending Tasks for User',
          data: topUsersData.map(userData => userData.count),
          backgroundColor: backgroundColors,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 15,
          padding: 10,
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('dashboard.dashboard')}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t('dashboard.project')}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Grid container spacing={3}>
          {projects.map((project) => (
            <Grid item xs={12} key={project.id}>
              <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                <Typography variant="h6">
                  {t('dashboard.project_name')}: {project.name}
                </Typography>
                <Typography variant="subtitle1">
                  {t('dashboard.task_count')}: {project.tasks?.length || 0}
                </Typography>
                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                  {project.tasks && project.tasks.length > 0 ? (
                    <>
                      <Grid item xs={12} md={4}>
                        <Paper style={{ padding: '16px', marginBottom: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <Typography variant="subtitle1" align="center" style={{ marginBottom: '16px' }}>
                            {t('dashboard.task_by_status')}
                          </Typography>
                          <div style={{ height: '200px', width: '250px' }}>
                            <Doughnut data={generateStatusData(project.tasks || [])} options={chartOptions} />
                          </div>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Paper style={{ padding: '16px', marginBottom: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <Typography variant="subtitle1" align="center" style={{ marginBottom: '16px' }}>
                            {t('dashboard.task_by_target_date')}
                          </Typography>
                          <div style={{ height: '200px', width: '235px' }}>
                            <Doughnut data={generateTargetDateData(project.tasks || [])} options={chartOptions} />
                          </div>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Paper style={{ padding: '16px', marginBottom: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <Typography variant="subtitle1" align="center" style={{ marginBottom: '16px' }}>
                            {t('dashboard.pending_user')}
                          </Typography>
                          <div style={{ height: '200px', width: '250px' }}>
                            <Doughnut data={generateUserData(project.tasks || [])} options={chartOptions} />
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', textAlign: 'center' }}>
                      <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                        {t('dashboard.no_task')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Dashboard;
