import React from "react";
import TaskMailPage from "../../components/TaskManagement/TaskMailPage";


const TaskMail: React.FC = () => {

    return(
        <TaskMailPage />
    )

}

export default TaskMail;
