import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Card, CardContent, Avatar, TextField, Tooltip, IconButton, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import { Comment, History } from '../../../models/Task/Pano';
import { useAuth } from '../../../context/AuthContext';
import TaskHistory from './TaskHistory';
import { useTranslation } from 'react-i18next';


interface TaskCommentAndHistoryProps {
    taskComment: Comment[];
    onCommentAndHistoryChange: (newComments: Comment[]) => void;
    internalCommentPermission: boolean;
    externalCommentPermission: boolean;
    internalHistoryPermmission: boolean;
    externalHistoryPermission: string;
    type: string;
    visibleInternalComment: boolean;
    visibleExternalComment: boolean;
    visibleInternalHistory: boolean;
    visibleExternalHistory: boolean;
    taskID?: string;
    allHistory: History[];
    onChangeTab: (tab: string) => void;
}

const TaskCommentAndHistory: React.FC<TaskCommentAndHistoryProps> = ({
    taskComment,
    onCommentAndHistoryChange,
    internalCommentPermission,
    externalCommentPermission,
    internalHistoryPermmission,
    externalHistoryPermission,
    type,
    visibleInternalComment,
    visibleExternalComment,
    visibleInternalHistory,
    visibleExternalHistory,
    taskID,
    allHistory,
    onChangeTab
}) => {

    const [mainTab, setMainTab] = useState("comment");
    const [commentTab, setCommentTab] = useState("internal");
    const [historyTab, setHistoryTab] = useState("internal");
    const { user } = useAuth();
    const [comments, setComments] = useState<Comment[]>();
    const [history, setHistory] = useState<History[]>();
    const [filteredComment, setFilteredComments] = useState<Comment[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [addComment, setAddComment] = useState(false);
    const [newComment, setNewComment] = useState("");
    const { t } = useTranslation('global');

    useEffect(() => {
        setComments(taskComment || []);
        setHistory(allHistory || []);
    }, [taskComment, allHistory]);

    useEffect(() => {
        setFilteredComments(comments ? comments.filter((comm) => comm.type === commentTab) : []);
        setFilteredHistory(history ? history.filter((hist) => hist.historyType === historyTab) : []);
    }, [comments, commentTab, history, historyTab]);

    useEffect(() => {
        setCommentTab(visibleInternalComment ? "external" : visibleExternalComment ? "internal" : (visibleExternalComment && visibleInternalComment) ? "" : commentTab);
        setHistoryTab(visibleInternalHistory ? "external" : visibleExternalHistory ? "internal" : (visibleExternalHistory && visibleInternalHistory) ? "" : historyTab);
    }, [visibleInternalComment, visibleExternalComment, commentTab, visibleInternalHistory, visibleExternalHistory, historyTab, taskComment]);


    const handleMainTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setMainTab(newValue);
        onChangeTab(newValue);
    };

    const handleCommentTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCommentTab(newValue);
    };

    const handleHistoryTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setHistoryTab(newValue);
    };

    const handleNewCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewComment(value);
    };

    const saveComment = () => {
        const updatedComments = [
            ...(comments || []),
            {
                comment: newComment,
                owner: user?.firstname + " " + user?.lastname,
                type: commentTab,
            },
        ];

        setComments(updatedComments);
        onCommentAndHistoryChange(updatedComments);
        setNewComment("");
    };

    const deleteComment = (index: number) => {

        if (comments) {
            const newCommand = comments.filter((comment) => comment.type !== commentTab);

            filteredComment.splice(index, 1);

            const allCom = [...newCommand, ...filteredComment];

            setComments(allCom);
            onCommentAndHistoryChange(allCom);
        } else {
            console.log("found any comments");
        }
    };

    return (

        <Box sx={{ minWidth: "50vw" }}>

            <Grid container size={12} sx={{ marginBottom: "10px" }}>
                <Tabs
                    value={mainTab}
                    onChange={handleMainTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="main tabs"
                >
                    <Tab
                        value="comment"
                        label={t('task.comments')}
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            }
                        }}
                    />
                    <Tab
                        value="history"
                        label={t('task.history')}
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            },
                        }}
                    />
                </Tabs>

            </Grid>


            <Grid container size={12} sx={{ marginBottom: "10px" }}>

                {mainTab === "comment" ? (

                    <Grid container>
                        <Grid size={12}>
                            <Tabs
                                value={commentTab}
                                onChange={handleCommentTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="main tabs"

                            >
                                {!visibleInternalComment ? <Tab value="internal" label={t('task.internal')} sx={{ fontSize: "12px" }} /> : null}
                                {(type === "parent" && !visibleExternalComment) ? (<Tab value="external" label={t('task.external')} sx={{ fontSize: "12px" }} />) : null}
                            </Tabs>
                        </Grid>

                        <Grid size={10}>
                            <div>
                                {filteredComment && filteredComment.length > 0 ? (
                                    filteredComment.map((comm, index) => (

                                        <Card square style={{ margin: "15px", minWidth: "40vw" }} key={index}>
                                            <CardContent>
                                                <Box display="flex" alignItems="center">

                                                    <Tooltip title={comm.owner}>
                                                        <Avatar>
                                                            {comm.owner[0].toUpperCase()}
                                                        </Avatar>
                                                    </Tooltip>
                                                    <TextField
                                                        value={comm.comment}
                                                        style={{ marginLeft: "10px", minWidth: "30vw" }}
                                                    />

                                                    <IconButton
                                                        onClick={() => { deleteComment(index) }}
                                                        disabled={(user?.firstname + " " + user?.lastname) !== comm.owner}
                                                    >
                                                        <DeleteIcon
                                                            style={{ color: ((user?.firstname + " " + user?.lastname) !== comm.owner) ? "grey" : "red" }}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </CardContent>
                                        </Card>

                                    ))
                                ) : (
                                    null
                                )}
                            </div>
                        </Grid>
                    </Grid>



                ) : mainTab === "history" ? (

                    <Grid container>
                        <Grid size={12}>
                            <Tabs
                                value={historyTab}
                                onChange={handleHistoryTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="main tabs"

                            >
                                {!visibleInternalHistory ? <Tab value="internal" label={t('task.internal')} sx={{ fontSize: "12px" }} /> : null}
                                {(type === "parent" && !visibleExternalHistory) ? (<Tab value="external" label={t('task.external')} sx={{ fontSize: "12px" }} />) : null}
                            </Tabs>
                        </Grid>
                        <Grid size={12}>
                            <TaskHistory taskHistory={filteredHistory || []} />
                        </Grid>
                    </Grid>

                ) : null}
            </Grid>

            <Grid container size={12}>
                {addComment ? (

                    <Grid size={10} display="flex">
                        <Card>
                            <CardContent style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField
                                    value={newComment}
                                    onChange={handleNewCommentChange}
                                    style={{ minWidth: "30vw", marginRight: "8px" }} // Sağ tarafta biraz boşluk bırakmak için marginRight ekledik
                                />

                                <IconButton onClick={() => {
                                    saveComment();
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}>
                                    <SendIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>



                ) : null}

                <Grid container size={12} justifyContent="flex-end" alignItems="flex-end" sx={{ minWidth: "30vw" }}>
                    {mainTab === "comment" ? (
                        <Grid>
                            <Button
                                variant="contained"
                                size="small"
                                style={{
                                    backgroundColor:
                                        ((commentTab === "internal" && internalCommentPermission) ? true : false)
                                            ||
                                            ((commentTab === "external" && externalCommentPermission) ? true : false) ?
                                            "lightgray" :
                                            "#0b2343",
                                    marginLeft: "10px"
                                }}
                                onClick={() => {
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}

                                disabled={((commentTab === "internal" && internalCommentPermission) ? true : false) || ((commentTab === "external" && externalCommentPermission) ? true : false)}

                            >
                                {t('task.add_comment')}
                            </Button>
                        </Grid>

                    ) : null
                    }
                </Grid>


            </Grid>
        </Box>
    );
};

export default TaskCommentAndHistory;