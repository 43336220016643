import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, IconButton, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import Grid from '@mui/material/Grid2';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';

import TaskNameField from './TaskNameField';
import TaskUserSelect from './TaskUserSelect';
import TaskStatusField from './TaskStatusField';
import TaskPriorityField from './TaskPriorityField';
import TaskAnswerField from './TaskAnswerField';
import TaskDatePicker from './TaskDatePicker';
import TaskFileUploadField from './TaskFileUploadField';
import DeleteModal from '../../Common/DeleteModal';
import SubtaskDetail from './SubtaskDetail';
import { Subtask } from '../../../models/Task/Pano';
import { Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';
import { Permission } from '../../../models/Task/Pano';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';

interface SubtaskProps {
    parentTask: Task;
    onRefresh: (refresh: boolean) => void;
    coordinatorName: string;
    index: number;
    onIndex: (ind: number) => void;
    onCloseTable: (curr: number) => void;
}


const SubtaskTable: React.FC<SubtaskProps> = ({ parentTask, onRefresh, coordinatorName, index, onIndex, onCloseTable }) => {

    const [task, setTask] = useState<Task>();
    const [subtaskList, setSubtask] = useState<Subtask[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<string | null>(null);
    const [taskIn, setTaskIn] = useState<number>(0);

    const [onchangeSubtask, setOnchangeSubtask] = useState<Subtask | null>(null)

    const [subtaskOpen, setSubtaskOpen] = useState<boolean>(false);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const { user } = useAuth();
    const {t} = useTranslation('global');

    const getMyTask = useCallback(async () => {
        const response = await TaskManagementService.get("task/" + parentTask.taskID, "json");
        const taskData: Task = response as Task;
        setTask(taskData);
        setSubtask(taskData.subtasks);
    }, [parentTask]);


    useEffect(() => {
        setTaskIn(index)
        getMyTask();
    }, [index, getMyTask]);


    useEffect(() => {
        const fetchTask = async () => {
            const response = await TaskManagementService.get("task/" + parentTask.taskID, "json");
            const taskData: Task = response as Task;
            setTask(taskData);
            setSubtask(taskData.subtasks);
        };

        fetchTask();
    }, [parentTask.taskID]);

    const handleSubtaskFieldChange = (value: any, subtaskID: string, field: string): void => {

        const allSubtask = [...subtaskList];

        if (task?._id) {

            if (field === "task") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                setOnchangeSubtask(mySubtask)
            } else if (field === "response") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                setOnchangeSubtask(mySubtask)
            } else if (field === "responsible") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                subtaskUpdate(mySubtask);
            } else if (field === "status") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                subtaskUpdate(mySubtask);
            } else if (field === "priority") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                subtaskUpdate(mySubtask);
            } else if (field === "targetDate") {
                const mySubtask = allSubtask.filter((sub) => sub.taskID === subtaskID)[0];
                mySubtask[field] = value;
                subtaskUpdate(mySubtask);
            }
        }
        else {
            console.error("Task ID is undefined");
        }

    };

    const subtaskUpdate = async (subtask: Subtask) => {
        if (subtask) {
            const response = await TaskManagementService.update(subtask.taskID, subtask, "task/subtask");
            console.log("subtask update response -> ", response);
            getMyTask()
            onRefresh(true);
        }
    };


    const updateSubTask = async (isBlur: boolean) => {

        if (isBlur) {
            if (onchangeSubtask) {
                console.log("buradaki subtask -> ", onchangeSubtask);
                const response = await TaskManagementService.update(onchangeSubtask.taskID, onchangeSubtask, "task/subtask");
                console.log(" subtask update response -> ", response);
                getMyTask()
                onRefresh(true);
            } else {
                console.log("parent task id not found");
            }
        } else {
            console.log("you cant update")
        }


    };

    const deleteSubtask = async (id: string) => {

        try {
            if(subtaskList.length === 1){
                onCloseTable(index);
            }
            const response = await TaskManagementService.delete(id, "task/subtask");
            console.log("subtask delte response -> ", response);
            getMyTask();

        } catch (error) {
            console.log(error);
            getMyTask()
        }
    };

    const uploadSubtaskFile = async (file: File, subtaskId: string) => {
        const formData = new FormData();
        formData.append('files', file);
        const fileUrl = "file/" + task?.taskID + "/" + subtaskId;
        const response = await TaskManagementService.create(formData, fileUrl);

        getMyTask()

        console.log("my file subtask upload response -> ", response);
    };

    const HeadStyle = {
        fontWeight: 'bold',
        fontSize: "13px",
        maxWidth: "10vh",
    };

    const subtaskOpenFun = () => {
        setSubtaskOpen(!subtaskOpen);
        getMyTask()
    };

    const checkUnvisibleField = (fieldName: keyof Permission) => {
        if (subtaskList.length > 0) {
            return subtaskList.every((subtask) => {
                return subtask.subtaskPermissions?.every((subPerm) => subPerm.permissions?.[fieldName] === "UnvisibleF");
            });
        }
    };

    const visibleForTheSubtask = (fieldName: keyof Permission, subtask: Subtask) => {
        if (subtask) {
            return subtask.subtaskPermissions?.every((subPerm) => subPerm.permissions?.[fieldName] === "UnvisibleF") ? true : false;
        }
    };

    return (
        <Grid size={11} display="flex" sx={{ marginLeft: "4%" }}>
            <DeleteModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(!deleteModalOpen)}
                onResponse={(response) => {
                    if (response) {
                        deleteSubtask(selectedDeleteIndex || "");
                        setDeleteModalOpen(!deleteModalOpen);
                    } else {
                        setDeleteModalOpen(!deleteModalOpen);
                        setSelectedDeleteIndex(null);
                    }
                }}
            />
            <SubtaskDetail
                open={subtaskOpen}
                onClose={() => { subtaskOpenFun(); onIndex(taskIn) }}
                subtaskID={selectedSubtask?.taskID || ""}
                parentTaskID={parentTask.taskID || ""}
                subtaskIndex={selectedIndex}
                onRefresh={(refresh) => {
                    if (refresh) {
                        getMyTask();
                    }
                }}
            />
            <SubdirectoryArrowRightIcon fontSize="large" />
            <Table size="small" style={{ backgroundColor: "whitesmoke" }}>
                <TableHead sx={{ backgroundColor: "whitesmoke" }}>
                    <TableRow>
                        {!checkUnvisibleField("taskID") ? (<TableCell sx={HeadStyle}>{t('task.subtask_id')}</TableCell>) : null}
                        {!checkUnvisibleField("task") ? (<TableCell sx={HeadStyle}>{t('task.subtask')}</TableCell>) : null}
                        {!checkUnvisibleField("priority") ? (<TableCell sx={HeadStyle}>{t('task.priority')}</TableCell>) : null}
                        {!checkUnvisibleField("responsible") ? (<TableCell sx={HeadStyle}>{t('task.responsible')}</TableCell>) : null}
                        {!checkUnvisibleField("status") ? (<TableCell sx={HeadStyle}>{t('task.status')}</TableCell>) : null}
                        {!checkUnvisibleField("creationDate") ? (<TableCell sx={HeadStyle}>{t('task.creation_date')}</TableCell>) : null}
                        {!checkUnvisibleField("targetDate") ? (<TableCell sx={HeadStyle}>{t('task.target_date')}</TableCell>) : null}
                        {!checkUnvisibleField("response") ? (<TableCell sx={HeadStyle}>{t('task.response')}</TableCell>) : null}
                        {!checkUnvisibleField("documents") ? (<TableCell sx={HeadStyle}>{t('task.docs')}</TableCell>) : null}
                        <TableCell sx={HeadStyle}>{t('task.actions')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {subtaskList?.map((subtask, index) => (
                        <TableRow key={index}>
                            {!checkUnvisibleField("taskID") ? (
                                <TableCell style={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px", width: 7 }} onClick={() => {
                                    setSelectedSubtask(subtask);
                                    setSelectedIndex(index);
                                    subtaskOpenFun();
                                }}>
                                    <p>{subtask.taskID}</p>
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("task") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("task", subtask) ? (
                                        <TaskNameField item={subtask.task}
                                            rows={1}
                                            multiline={false}
                                            onTaskNameChange={(newValue) => { handleSubtaskFieldChange(newValue, subtask.taskID, "task") }}
                                            onBlurCallBack={(isBlurred) => { updateSubTask(isBlurred) }}
                                            permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.task === "Editable")}
                                            label={true}
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}

                            {!checkUnvisibleField("priority") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("priority", subtask) ? (
                                        <TaskPriorityField
                                            item={subtask.priority}
                                            onPriorityChange={(newPriority) => { handleSubtaskFieldChange(newPriority, subtask.taskID, "priority") }}
                                            priorityPermission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.priority === "Editable")}
                                        />
                                    ) : null}

                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("responsible") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("responsible", subtask) ? (
                                        <TaskUserSelect
                                            user={subtask?.responsible || ""}
                                            onTaskUserChange={(newUser) => { handleSubtaskFieldChange(newUser, subtask.taskID, "responsible") }}
                                            permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.responsible === "Editable")}
                                            show={false}
                                            filter='Participant'
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("status") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("status", subtask) ? (
                                        <TaskStatusField
                                            item={subtask.status}
                                            onStatusChange={(newStatus) => { handleSubtaskFieldChange(newStatus, subtask.taskID, "status") }}
                                            allStatus={subtask.possibleNextStatus.map((status) => status) || []}
                                            permission={!subtask?.subtaskPermissions?.some((subPerm) => subPerm.permissions.status === "Editable")}
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("creationDate") ? (
                                <TableCell style={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px", width: 7 }} onClick={() => {
                                    setSelectedSubtask(subtask);
                                    setSelectedIndex(index);
                                    subtaskOpenFun();
                                }}>
                                    {!visibleForTheSubtask("creationDate", subtask) ? (
                                        <p style={{ fontSize: 16 }}>
                                            {subtask.creationDate
                                                ? `${new Date(subtask.creationDate).getDate().toString().padStart(2, '0')}.${(new Date(subtask.creationDate).getMonth() + 1).toString().padStart(2, '0')}.${new Date(subtask.creationDate).getFullYear()}`
                                                : ""}
                                        </p>
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("targetDate") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("targetDate", subtask) ? (
                                        <TaskDatePicker
                                            date={subtask.targetDate}
                                            onTaskDateChange={(newDate) => { handleSubtaskFieldChange(newDate, subtask.taskID, "targetDate") }}
                                            minDate={subtask.creationDate || new Date()}
                                            permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.targetDate === "Editable")}
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("response") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("response", subtask) ? (
                                        <TaskAnswerField
                                            item={subtask.response}
                                            rows={1} multiline={false}
                                            onTaskAnswerChange={(newValue) => { handleSubtaskFieldChange(newValue, subtask.taskID, "response") }}
                                            onBlurCallBack={(isBlurred) => { updateSubTask(isBlurred) }}
                                            permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.response === "Editable")}
                                            label={true}
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {!checkUnvisibleField("documents") ? (
                                <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                    {!visibleForTheSubtask("documents", subtask) ? (
                                        <TaskFileUploadField
                                            allDocuments={subtask.documents}
                                            onFileChange={(newFile) => { uploadSubtaskFile(newFile, subtask.taskID) }}
                                            type='dashboard'
                                            permission={!subtask?.subtaskPermissions.some((subPerm) => subPerm.permissions.documents === "Editable")}
                                        />
                                    ) : null}
                                </TableCell>
                            ) : null}
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", width: 7 }}>
                                <IconButton disabled={(parentTask.responsible !== user?._id) && (coordinatorName !== user?._id)} onClick={() => {
                                    setDeleteModalOpen(!deleteModalOpen);
                                    setSelectedDeleteIndex(subtask.taskID);
                                }}>
                                    <DeleteIcon sx={{ color: (parentTask.responsible !== user?._id) && (coordinatorName !== user?._id) ? "lightgrey" : "red" }} />
                                </IconButton>
                                <IconButton onClick={() => {
                                    setSelectedSubtask(subtask);
                                    setSelectedIndex(index);
                                    subtaskOpenFun();
                                }}>
                                    <ListIcon sx={{ color: 'gray' }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <SubdirectoryArrowLeftIcon fontSize="large" />
        </Grid>


    );
};

export default SubtaskTable;