import React from "react";
import { Dialog, DialogActions, IconButton, DialogContent, Card, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


interface RegulationRefDetailProps {
    open: boolean;
    onClose: () => void;
    content: string;
}

const RegulationRefDetail: React.FC<RegulationRefDetailProps> = ({ open, onClose, content}) => {

    return (

        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogActions>
                <IconButton onClick={() => { onClose(); }}>
                    <CloseIcon />
                </IconButton>

            </DialogActions>
            <DialogContent>
                <Card square style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
                    <CardContent>
                        <p style={{ color: "#0b2343", fontWeight: "bold", fontSize: "16px" }}>{content}</p>
                    </CardContent>
                </Card>
            </DialogContent>

        </Dialog>

    )

}

export default RegulationRefDetail;