import React, { useState } from "react";
import { Paper, Typography, Tabs, Tab, Card } from "@mui/material";
import Grid from '@mui/material/Grid2';


import ChangePassword from "../components/Profile/ChangePassword";
import UserInformation from "../components/Profile/UserInformation";
import { useTranslation } from "react-i18next";




const Profile: React.FC = () => {

    const [profileTabValue, setProfileTabValue] = useState("user-info");
    const { t } = useTranslation('global');

    const handleProfileTabValueChange = (event: React.SyntheticEvent, value: string) => {
        setProfileTabValue(value);
    };

    return (

        <Paper>
            <Grid container sx={{ margin: "15px" }}>
                <Grid size={12} sx={{ marginBottom: "10px" }}>
                    <Typography variant="h4" sx={{ color: "#0b2343" }}>
                        {t('profile.profile')}
                    </Typography>
                </Grid>
                <Grid size={12} sx={{ marginBottom: "10px" }}>
                    <Card>
                        <Grid size={12}>
                            <Tabs
                                value={profileTabValue}
                                onChange={handleProfileTabValueChange}
                            >
                                <Tab
                                    value="user-info"
                                    label={t('profile.user_information')}
                                    sx={{ color: "#0b2343", fontSize: "18px", textTransform: "none" }}
                                />
                                <Tab
                                    value="change-password"
                                    label={t('profile.change_password')}
                                    sx={{ color: "#0b2343", fontSize: "18px", textTransform: "none" }}
                                />
                            </Tabs>
                        </Grid>
                        <Grid size={12} sx={{ margin: "20px" }}>
                            {profileTabValue === "user-info" ? (
                                <UserInformation />
                            ) : profileTabValue === "change-password" ? (
                                <ChangePassword />
                            ) : null
                            }
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    )
}


export default Profile;
