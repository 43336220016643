import React, { useState, useCallback } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TaskAnsweFieldProps {
  item: string;
  multiline: boolean;
  rows: number;
  onTaskAnswerChange: (newTaskName: string) => void;
  onBlurCallBack: (isBlurred: boolean) => void;
  permission: boolean;
  label: boolean;
}

const TaskAnswerField: React.FC<TaskAnsweFieldProps> = ({ item, multiline, rows, onTaskAnswerChange, onBlurCallBack, permission, label }) => {
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [value, setValue] = useState<string>(item);
  const { t } = useTranslation('global');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onTaskAnswerChange(newValue);
    setAnyChange(true);
  }, [onTaskAnswerChange]);

  const handleBlur = useCallback(() => {
    if (anyChange) {
      onBlurCallBack(true);
      setAnyChange(false);
    }
  }, [anyChange, onBlurCallBack]);


  return (
    <TextField
      fullWidth
      label={label ? null : t('task.response')}
      value={value}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      onBlur={handleBlur}
      disabled={permission}
    />
  );
};

export default TaskAnswerField;