import React, { useState } from "react";
import Grid from '@mui/material/Grid2';
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TaskCreateComponentProps {
    onAddTask: (taskName: string) => void;
    disabled: boolean;
}

const TaskCreateComponent: React.FC<TaskCreateComponentProps> = ({ onAddTask, disabled }) => {

    const [newTaskName, setNewTaskName] = useState("");
    const {t} = useTranslation('global');

    const createTask = () => {
        if (newTaskName.trim()) {
            onAddTask(newTaskName);
            setNewTaskName("");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && newTaskName !== "") {
            createTask();
        }
    };

    return (
        <Grid columns={12} sx={{ margin: "5px" }}>
            <Grid size={3} sx={{ margin: "10px" }} display="flex" flexDirection="column">
                <TextField
                    value={newTaskName}
                    disabled={disabled}
                    onChange={(e) => { setNewTaskName(e.target.value); }}
                    onBlur={() => {
                        if (newTaskName !== "") {
                            createTask();
                        }
                    }}
                    onKeyDown={handleKeyDown}
                    label={t('task.new_task')}
                    fullWidth
                />
                {newTaskName !== "" ? (
                    <p style={{ marginTop: "10px", fontSize: 13 }}>{t('task.click_for_create')}</p>
                ) : null}
            </Grid>
        </Grid>
    )
};

export default TaskCreateComponent;
