import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, Paper, Table, TableBody, TableHead, TableRow, TableCell, Box, TablePagination, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Grid from '@mui/material/Grid2';
import { TaskManagementService } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CommentIcon from '@mui/icons-material/Comment';


//Task Man. Field Components
import TaskNameField from './component/TaskNameField';
import TaskDatePicker from './component/TaskDatePicker';
import TaskStatusField from './component/TaskStatusField';
import TaskAnswerField from './component/TaskAnswerField';
import TaskFileUploadField from './component/TaskFileUploadField';
import ParentTaskDetail from './component/ParentTaskDetail';
import TaskUserSelect from './component/TaskUserSelect';
import SubtaskTable from './component/SubtaskTable';
import TaskCreateComponent from './component/TaskCreateComponent';
import DeleteModal from '../Common/DeleteModal';
import TaskHeader from './component/TaskHeader';
import TaskCombinedField from './component/TaskCombinedField';
import { Project } from '../../models/Task/Pano';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import SessionExpire from '../Common/SessionExpire';


//Task Man. Model
import { Task } from '../../models/Task/Pano'
import { Permission } from '../../models/Task/Pano';

const TaskTable: React.FC = () => {
  const { code } = useParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [openedSubtaskList, setOpenedSubtaskList] = useState<number[]>([]);
  const [taskDetailOpen, setTaskDetailOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<string>("");
  const [currentProjectDetail, setCurrentProjectDetail] = useState<Project>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslation('global');
  const [loading, setLoading] = useState<boolean>(false);
  const [columnsSort, setColumnsSort] = useState<Record<string, 'asc' | 'desc'>>({
    "taskID": 'asc',
    "refNum": 'asc',
    "regulationRef": 'asc',
    "task": 'asc',
    "requestDate": 'asc',
    "responsible": 'asc',
    "status": 'asc',
    "creationDate": 'asc',
  });

  const [columnSortIcon, setColumnsSortIcon] = useState<Record<string, boolean>>({
    "taskID": false,
    "refNum": false,
    "regulationRef": false,
    "task": false,
    "requestDate": false,
    "responsible": false,
    "status": false,
    "creationDate": false,
  });

  const [condtions, setConditions] = useState<Array<Record<string, any>>>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const totalPages = Math.ceil(tasks.length / rowsPerPage);
  const [session, setSession] = useState<boolean>(false);


  const addTask = async (newTaskName: string) => {

    const newTask: Partial<Task> = {
      projectCode: code,
      task: newTaskName,
      responsible: null,
      status: 'Not Assigned',
      targetDate: null,
      response: '',
      approver: null,
      internalAuditor: null,
      independentAuditor: null,
      article: "",
      paragraph: "",
      regulationRef: "",
      priority: '',
      refNum: "",
      title: '',
      lastUpdate: new Date(),
      creationDate: new Date(),
      documents: [],
      comments: [],
      subtasks: [],
    };

    const response = await TaskManagementService.create(newTask, "task");
    console.log("my task create response -> ", response);

    getAllTask();
  };

  const getAllTask = useCallback(async (condition: Record<string, any>[] = []) => {
    setLoading(true);
    if (code !== "") {
      try {
        const url = "task/read-all/" + code;
        const response = await TaskManagementService.getAllWithParam({ conditions: condition || condtions }, url);
        const allTask: Task[] = response as Task[];
        setTasks([...allTask]);
      } catch (error) {
        console.log(error);
        setSession(true);
      } finally {
        setLoading(false);
      }

    } else {
      console.log("No project has been selected");
    }
  }, [code, condtions]);

  const handleSessionCheck = () => {
    setSession(false);
    window.location.href = '/login';
  };

  const getProjectDetail = async (prCode: string) => {
    const response = await TaskManagementService.get("project/" + prCode, "json");
    const taskProject: Project = response as Project;
    setCurrentProjectDetail(taskProject);
  };

  useEffect(() => {
    if (code !== "") {
      getProjectDetail(code || "");
      getAllTask();
    } else {
      console.log("No project has been selected");
    }
  }, [code]);


  const addSubtask = async (task: Task) => {

    let newSubtask = {
      task: '',
      responsible: null,
      status: 'Not Assigned',
      targetDate: null,
      response: '',
      priority: '',
      lastUpdate: new Date(),
      documents: [],
      comments: [],
      creationDate: new Date(),
    };
    const response = await TaskManagementService.create(newSubtask, "task/subtask/" + task.taskID)
    console.log("my subtask response -> ", response);
    getAllTask();

  };


  const handleFieldChange = (value: any, id: string, field: string): void => {

    setColumnsSort({});

    const updatedTask = tasks.find((task) => task.taskID === id);
    if (updatedTask) {
      if (field === "priority") {
        updatedTask.priority = value;
        updateTaskOnchangeEvent(updatedTask);
      } else if (field === "status") {
        updatedTask.status = value;
        updateTaskOnchangeEvent(updatedTask);
      } else if (field === "responsible") {
        updatedTask.responsible = value;
        updateTaskOnchangeEvent(updatedTask);
      } else if (field === "targetDate") {
        updatedTask.targetDate = value;
        updateTaskOnchangeEvent(updatedTask);
      }
      else if (field === "task") {
        updatedTask.task = value;
      } else if (field === "response") {
        updatedTask.response = value;
      } else if (field === "regu") {
        updatedTask.regulationRef = value;
      }
      const allTask = tasks.map(task =>
        task.taskID === updatedTask.taskID ? updatedTask : task
      );
      setTasks(allTask);
    }
  };

  const uploadFile = async (file: File, task: Task) => {
    const formData = new FormData();
    task.lastUpdate = new Date();
    formData.append('files', file);
    const fileUrl = "file/" + task.taskID;
    const response = await TaskManagementService.create(formData, fileUrl);
    console.log("my file upload response -> ", response);
    getAllTask();
  };

  const updateTaskOnchangeEvent = async (task: Task) => {
    task.lastUpdate = new Date();
    const response = await TaskManagementService.update(task.taskID, task, "task");
    console.log("my response -> ", response);
    getAllTask();
  };

  const updateTaskIsBlur = async (isBlur: boolean, field: string, task: Task) => {
    if (isBlur) {
      task.lastUpdate = new Date();
      const response = await TaskManagementService.update(task.taskID, task, "task");
      console.log("my response -> ", response);
      getAllTask();
    } else {
      console.log("update yapamazsın");
    }
  };

  const deleteTask = async () => {
    const response = await TaskManagementService.delete(deletedItem, "task");
    console.log("task delete response -> ", response);
    getAllTask();
    setDeletedItem("");
    setDeleteModalOpen(!deleteModalOpen);
  };

  const tableStyle = {
    minWidth: "20vh",
    fontWeight: 'bold',
    fontSize: "15px",
  };

  const taskDetailOp = () => {
    getAllTask();
    setTaskDetailOpen(!taskDetailOpen);
  };

  const subTaskOpenClose = (item: number) => {
    const list = [...openedSubtaskList];

    if (list.includes(item)) {
      const updatedList = list.filter((i) => i !== item);
      setOpenedSubtaskList(updatedList);
    }
    else {
      setOpenedSubtaskList([...list, item]);
    }
  };

  const redirectProjectPage = () => {
    navigate("/task-management/project");
  };

  const decodeUTF8 = (input: string) => {
    const byteArray = new Uint8Array(input.split('').map(char => char.charCodeAt(0)));
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(byteArray);
  };


  const checkUnvisibleField = (fieldName: keyof Permission) => {
    if (tasks.length > 0) {
      return tasks.every((task) => {
        return task.taskPermission?.every((taskPerm) => taskPerm.permissions?.[fieldName] === "UnvisibleF");
      });
    }
    return false;
  };

  const visibleForTheTask = (fieldName: keyof Permission, task: Task) => {
    if (task) {
      return task.taskPermission?.every((taskPerm) => taskPerm.permissions?.[fieldName] === "UnvisibleF" ? true : false)
    }
  };

  const getDate = (date: Date | null) => {
    return date
      ? `${new Date(date).getUTCDate().toString().padStart(2, '0')}.${(new Date(date).getUTCMonth() + 1).toString().padStart(2, '0')}.${new Date(date).getUTCFullYear()}`
      : "";
  };

  const sortingColumns = (field: keyof Task, sortValue = "") => {
    const currentSortOrder = sortValue || columnsSort[field];
    const sortedTasks = [...tasks].sort((a, b) => {
      let aField, bField;

      if (field === "status") {
        const statusOrder: { [key: string]: number } = {
          "Cancelled": 0,
          "Not Assigned": 1,
          "To Do": 2,
          "In Progress": 3,
          "Awaiting Approver": 4,
          "Awaiting Int. Auditor": 5,
          "Submitted": 6,
          "Done": 7
        };
        aField = statusOrder[a[field]] ?? -1;
        bField = statusOrder[b[field]] ?? -1;
      } else {
        aField = field === "taskID" ? Number(a[field].substring(a[field].lastIndexOf("--") + 2)) : a[field] ?? "";
        bField = field === "taskID" ? Number(b[field].substring(b[field].lastIndexOf("--") + 2)) : b[field] ?? "";
      }

      if (aField < bField) {
        return currentSortOrder === "asc" ? -1 : 1;
      }
      if (aField > bField) {
        return currentSortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    setTasks(sortedTasks);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setOpenedSubtaskList([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAlert = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: '10%',
          right: '5%',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: 'rgba(255, 152, 0, 0.4)',
          padding: '8px',
          borderRadius: '10px',
          width: '15%',
          marginBottom: '2%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
        }}
      >
        <p style={{ fontWeight: 'bold', color: '#0b2343' }}>{t('task.loading_task')}</p>
        <CircularProgress size={25} />
      </div>


    );
  };



  return (
    <Paper sx={{ p: 2, mb: 2, minWidth: "50vh", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)' }}>
      <SessionExpire open={session} onClose={handleSessionCheck} />

      <ParentTaskDetail
        open={taskDetailOpen}
        onClose={() => {
          taskDetailOp();
        }}
        taskID={selectedTask?.taskID || ""}
        currentProjectIndependentAuditor={currentProjectDetail?.independentAuditor || ""}
      />
      <Grid container>
        <TaskHeader
          projectCode={code || ""}
          onRefresh={(refresh) => {
            if (refresh) {
              getAllTask();
            }
          }}
          disabledUploadButton={currentProjectDetail?.coordinator !== user?._id}
          onChangeSearch={(search) => { setSearch(search); setPage(0); }}
          onSortChange={(column, sort) => {
            const updatedSort = { ...columnsSort, [column]: sort as "asc" | "desc" };
            setColumnsSort(updatedSort);
            sortingColumns(column as keyof Task, sort);
          }}
          onConditionsChange={(condtions) => { setConditions(condtions); getAllTask(condtions) }}
        />

        <Grid size={3} sx={{ mb: "10px" }} style={{ width: 'fit-content', textAlign: 'left', padding: '8px 16px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <p style={{ margin: 0, fontSize: 18, color: "#0b2343", fontWeight: 'bold', lineHeight: '1.2' }}>
            <span style={{ cursor: "pointer" }} onClick={() => { redirectProjectPage() }}>
              {t('sidebar.project')}
            </span> / {currentProjectDetail?.name}
          </p>
        </Grid>

        <Grid size={5}>
          {loading ? (
            getAlert()
          ) : null}
        </Grid>

      </Grid>
      <Grid container rowSpacing={2} columns={12}>
        <Grid size={12}>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead sx={{ backgroundColor: "whiteSmoke" }}>
                <TableRow>
                  {!checkUnvisibleField("taskID") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("taskID"); setColumnsSort((prevSort) => ({ ...prevSort, taskID: prevSort.taskID === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ fontWeight: 'bold', fontSize: "15px", cursor: "pointer", width: "auto" }}
                      onMouseEnter={() => setColumnsSortIcon({ "taskID": true })}
                      onMouseLeave={() => setColumnsSortIcon({ "taskID": false })}
                    >
                      <Box display="flex" alignItems="center">
                        <span style={{ whiteSpace: "nowrap" }}>{t('task.task_id')}</span>
                        {columnSortIcon["taskID"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("refNum") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("refNum"); setColumnsSort((prevSort) => ({ ...prevSort, refNum: prevSort.refNum === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ fontWeight: 'bold', fontSize: "15px", cursor: "pointer", width: "auto" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "refNum": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "refNum": false }))}>
                      <Box display="flex" alignItems="center">
                        <span style={{ whiteSpace: "nowrap" }}>{t('task.ref_num')}</span>
                        {columnSortIcon["refNum"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("regulationRef") ? (
                    <TableCell sx={tableStyle}>{t('task.regulation')}</TableCell>
                  ) : null}
                  {!checkUnvisibleField("task") ? (
                    <TableCell sx={tableStyle}>{t('task.task')}</TableCell>
                  ) : null}
                  {!checkUnvisibleField("requestDate") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("requestDate"); setColumnsSort((prevSort) => ({ ...prevSort, requestDate: prevSort.requestDate === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ ...tableStyle, cursor: "pointer" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "requestDate": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "requestDate": false }))}>
                      <Box display="flex" alignItems="center">
                        <span>{t('task.request_date')}</span>
                        {columnSortIcon["requestDate"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("responsible") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("responsible"); setColumnsSort((prevSort) => ({ ...prevSort, responsible: prevSort.responsible === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ ...tableStyle, cursor: "pointer" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "responsible": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "responsible": false }))}>
                      <Box display="flex" alignItems="center">
                        <span>{t('task.responsible')}</span>
                        {columnSortIcon["responsible"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("status") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("status"); setColumnsSort((prevSort) => ({ ...prevSort, status: prevSort.status === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ ...tableStyle, cursor: "pointer" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "status": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "status": false }))}>
                      <Box display="flex" alignItems="center">
                        <span>{t('task.status')}</span>
                        {columnSortIcon["status"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("creationDate") ? (
                    <TableCell
                      onClick={() => {
                        sortingColumns("creationDate"); setColumnsSort((prevSort) => ({ ...prevSort, creationDate: prevSort.creationDate === 'asc' ? 'desc' : 'asc' }));
                      }}
                      sx={{ ...tableStyle, cursor: "pointer" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "creationDate": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "creationDate": false }))}>
                      <Box display="flex" alignItems="center">
                        <span>{t('task.creation_date')}</span>
                        {columnSortIcon["creationDate"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("targetDate") ? (
                    <TableCell
                      onClick={() => { sortingColumns("targetDate"); setColumnsSort((prevSort) => ({ ...prevSort, targetDate: prevSort.targetDate === 'asc' ? 'desc' : 'asc' })); }}
                      sx={{ ...tableStyle, cursor: "pointer" }}
                      onMouseEnter={() => setColumnsSortIcon(({ "targetDate": true }))}
                      onMouseLeave={() => setColumnsSortIcon(({ "targetDate": false }))}>
                      <Box display="flex" alignItems="center">
                        <span>{t('task.target_date')}</span>
                        {columnSortIcon["targetDate"] && <SwapVertIcon />}
                      </Box>
                    </TableCell>
                  ) : null}
                  {!checkUnvisibleField("response") ? (
                    <TableCell sx={tableStyle}>{t('task.response')}</TableCell>
                  ) : null}
                  {!checkUnvisibleField("commentExternal") ? (
                    <TableCell sx={tableStyle}>{t('task.external_comment')}</TableCell>
                  ) : null}
                  {!checkUnvisibleField("documents") ? (
                    <TableCell sx={tableStyle}>{t('task.docs')}</TableCell>
                  ) : null}
                  {!checkUnvisibleField("task") ? (
                    <TableCell sx={tableStyle}>{t('task.actions')}</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks
                  .filter((task) =>
                    search === "" ||
                    task.taskID?.toLowerCase().includes(search.toLowerCase()) ||
                    task.task?.toLowerCase().includes(search.toLowerCase()) ||
                    task.regulationRef?.toLowerCase().includes(search.toLowerCase()) ||
                    task.responsible?.toLowerCase().includes(search.toLowerCase()) ||
                    task.status?.toLowerCase().includes(search.toLowerCase()) ||
                    task.response?.toLowerCase().includes(search.toLowerCase()) ||
                    task.documents?.some((doc) => decodeUTF8(doc?.fileName).toLowerCase().includes(search.toLowerCase())) ||
                    task.refNum?.toLocaleLowerCase().includes(search.toLowerCase()) ||
                    getDate(task.creationDate).toLowerCase().includes(search.toLowerCase()) ||
                    getDate(task.requestDate).toLowerCase().includes(search.toLowerCase()) ||
                    getDate(task.targetDate).toLowerCase().includes(search.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((task, index) => (
                    <React.Fragment key={task.taskID}>
                      {!task.subtasks.every((subtask) => subtask.subtaskPermissions.every((subPerm) => subPerm?.role === "no role")) || !task.taskPermission.every((taskPerm) => taskPerm?.role === "no role") ? (
                        <TableRow style={{ border: "1px black solid" }}>
                          {!checkUnvisibleField("taskID") ? (
                            <TableCell sx={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px" }} onClick={() => {
                              if (!visibleForTheTask("taskID", task)) {
                                setSelectedTask(task);
                                taskDetailOp();
                              }
                            }}>
                              <p style={{ display: "inline-block", color: "blue", whiteSpace: "nowrap", position: "relative" }}>{task.taskID}<span style={{ position: "absolute", bottom: 0, left: 0, height: "1px", backgroundColor: "blue", width: `${task.taskID.length * 8}px` }} /></p>
                            </TableCell>) : null}
                          {!checkUnvisibleField("refNum") ? (
                            <TableCell sx={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px" }} onClick={() => {
                              if (!visibleForTheTask("refNum", task)) {
                                setSelectedTask(task);
                                taskDetailOp();
                              }
                            }}>
                              {!visibleForTheTask("refNum", task) ? (
                                <p>{task.refNum}</p>
                              ) : null}
                            </TableCell>) : null}
                          {!checkUnvisibleField("regulationRef") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("regulationRef", task) ? (
                                <TaskCombinedField
                                  item={task.regulationRef}
                                  multiline={false}
                                  rows={1}
                                  label={false}
                                  disabled={!task.taskPermission?.some(taskPerm => taskPerm.permissions.regulationRef === "Editable")}
                                  onTaskCombinedFieldChange={(newValue) => { handleFieldChange(newValue, task.taskID, "regu") }}
                                  onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "regu", task) }}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}

                          {!checkUnvisibleField("task") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("task", task) ? (
                                <TaskNameField item={task.task}
                                  multiline={false}
                                  rows={1}
                                  onTaskNameChange={(newValue) => { handleFieldChange(newValue, task.taskID, "task") }}
                                  onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "name", task) }}
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.task === "Editable")}
                                  label={true}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}

                          {!checkUnvisibleField("requestDate") ? (
                            <TableCell sx={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px" }} onClick={() => {
                              if (!visibleForTheTask("requestDate", task)) {
                                setSelectedTask(task);
                                taskDetailOp();
                              }
                            }}>
                              {!visibleForTheTask("requestDate", task) ? (
                                <p style={{ fontSize: 16 }}>
                                  {getDate(task.requestDate)}
                                </p>
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("responsible") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("responsible", task) ? (
                                <TaskUserSelect
                                  user={task?.responsible || ""}
                                  onTaskUserChange={(newUser) => { handleFieldChange(newUser, task.taskID, "responsible") }}
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.responsible === "Editable")}
                                  show={false}
                                  filter='Participant'
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("status") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("status", task) ? (
                                <TaskStatusField
                                  item={task.status}
                                  onStatusChange={(newStatus) => { handleFieldChange(newStatus, task.taskID, "status") }}
                                  allStatus={task.possibleNextStatus?.map((status) => status) || []}
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.status === "Editable")}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("creationDate") ? (
                            <TableCell sx={{ cursor: "pointer", paddingTop: "1px", paddingBottom: "1px" }} onClick={() => {
                              if (!visibleForTheTask("creationDate", task)) {
                                setSelectedTask(task);
                                taskDetailOp();
                              }
                            }}>
                              {!visibleForTheTask("creationDate", task) ? (
                                <p style={{ fontSize: 16 }}>
                                  {getDate(task.creationDate)}
                                </p>
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("targetDate") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("targetDate", task) ? (
                                <TaskDatePicker
                                  date={task.targetDate}
                                  onTaskDateChange={(newDate) => { handleFieldChange(newDate, task.taskID, "targetDate") }}
                                  minDate={task.creationDate || new Date()}
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.targetDate === "Editable")}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("response") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("response", task) ? (
                                <TaskAnswerField
                                  item={task.taskPermission.every((taskPerm) => taskPerm.permissions.response !== "UnvisibleC*") ? task.response : ""}
                                  multiline={false} rows={1}
                                  onTaskAnswerChange={(newValue) => { handleFieldChange(newValue, task.taskID, "response"); }}
                                  onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "response", task); }}
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.response === "Editable")}
                                  label={true}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("commentExternal") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px", cursor: "pointer" }} onClick={() => {
                              if (!visibleForTheTask("commentExternal", task)) {
                                setSelectedTask(task);
                                taskDetailOp();
                              }
                            }}>
                              {!visibleForTheTask("commentExternal", task) ? (
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                                  <CommentIcon />
                                  <span>{task?.comments?.filter((comment) => comment.type === "external").length}</span>
                                </Box>
                              ) : null}
                            </TableCell>
                          ) : null}
                          {!checkUnvisibleField("documents") ? (
                            <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                              {!visibleForTheTask("documents", task) ? (
                                <TaskFileUploadField
                                  allDocuments={task.documents}
                                  onFileChange={(newFile) => { uploadFile(newFile, task); }}
                                  type='dashboard'
                                  permission={!task.taskPermission?.some((taskPerm) => taskPerm.permissions.documents === "Editable")}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                          <TableCell sx={{ paddingTop: "1px", paddingBottom: "1px" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                  disabled={currentProjectDetail?.coordinator !== user?._id}
                                  onClick={() => {
                                    setDeleteModalOpen(true);
                                    setDeletedItem(task.taskID);
                                  }}>
                                  <DeleteIcon sx={{ color: currentProjectDetail?.coordinator !== user?._id ? "lightgrey" : "red" }} />
                                </IconButton>
                                <IconButton onClick={() => {
                                  if (!visibleForTheTask("taskID", task)) {
                                    setSelectedTask(task);
                                    taskDetailOp();
                                  }
                                }}>
                                  <ListIcon sx={{ color: '#0b2343' }} />
                                </IconButton>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton disabled={(currentProjectDetail?.coordinator !== user?._id) && (task.responsible !== user?._id)}
                                  onClick={() => {
                                    addSubtask(task);
                                    if (!openedSubtaskList.includes(index)) {
                                      subTaskOpenClose(index);
                                      getAllTask();
                                    }
                                  }} sx={{ color: '#0b2343' }}>
                                  <AddIcon />
                                </IconButton>
                                <IconButton onClick={() => {
                                  subTaskOpenClose(index);
                                  getAllTask();
                                }} sx={{ color: '#0b2343' }}
                                  disabled={currentProjectDetail?.independentAuditor === user?._id}
                                >
                                  <ImportExportIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}

                      {openedSubtaskList.includes(index) && (
                        <TableRow>
                          <TableCell colSpan={14} sx={{ padding: 0 }}>
                            <SubtaskTable
                              parentTask={task}
                              coordinatorName={currentProjectDetail?.coordinator || ""}
                              index={index}
                              onIndex={(ind) => {
                                setOpenedSubtaskList(prevList => prevList.filter(item => item !== ind));
                                setTimeout(() => {
                                  setOpenedSubtaskList(prevList => [...prevList, ind]);
                                }, 3);
                              }}
                              onRefresh={(refresh) => {
                                if (refresh) {
                                  getAllTask();
                                }
                              }}
                              onCloseTable={(curr) => {
                                subTaskOpenClose(curr);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid size={12}>
          <TaskCreateComponent disabled={currentProjectDetail?.coordinator !== user?._id} onAddTask={(taskName) => { addTask(taskName); }} />
        </Grid>

        <Grid size={12} container alignItems="center" justifyContent="center">
          <Grid size={12} style={{ display: "flex", justifyContent: "center" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[20, 50]}
                component="div"
                count={tasks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('task.rows_per_page')}
              />

              <Select
                value={page}
                onChange={(e) => setPage(Number(e.target.value))}
                displayEmpty
                style={{ maxHeight: "30px" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                }}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <MenuItem key={index} value={index}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>


      </Grid>

      <DeleteModal
        open={deleteModalOpen}
        onClose={() => { setDeleteModalOpen(!deleteModalOpen) }}
        onResponse={(response) => {
          if (response) {
            deleteTask();
          } else {
            setDeletedItem("");
            setDeleteModalOpen(!deleteModalOpen);
          }
        }}

      />
    </Paper>

  );
};
export default TaskTable;