import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';



interface SuccessfulRejectProps {
    projectName: string,
    taskID: string;
    taskName: string;
    taskStatus: string;
}

const SuccessfulReject: React.FC<SuccessfulRejectProps> = ({projectName, taskID, taskName, taskStatus }) => {

    const [id, setID] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        setID(taskID);
        setName(taskName);
        setStatus(taskStatus);
    }, [taskID, taskName, taskStatus]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                backgroundColor: "#ffccc6",
                borderRadius: "8px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: 'column',
                width: '100%',
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DisabledByDefaultIcon sx={{ verticalAlign: 'middle', color: 'red' }} />
                <span style={{ marginLeft: "4px", fontSize: 36, fontWeight: 'bold', color: '#0b2343' }}>Task rejected.</span>
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: '16px', border: "2px solid red", padding: '16px', borderRadius: '8px', backgroundColor: '#fff' }}>
                <p style={{ fontSize: 20 }}><span style={{ color: "#0b2343", fontWeight: "bold" }}>Task-ID:</span> {id}</p>
                <p style={{ fontSize: 20 }}><span style={{ color: "#0b2343", fontWeight: "bold" }}>Task Name:</span> {name}</p>
                <p style={{ fontSize: 20 }}><span style={{ color: "#0b2343", fontWeight: "bold" }}>Task Status:</span> {status}</p>
            </Box>
        </Box>
    )


}


export default SuccessfulReject;